import React, { useEffect, useState } from "react";
import styles from "../shimpment.module.css";
import Dedit from "../../../static/icons/Dedit";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import { t } from "../../Translations/TranslationUtils";
import Pagination from "../../../services/Paginations";
import { FaSort } from "react-icons/fa";
import { APICALL } from "../../../services/ApiServices";
import customAlert from "../../../components/atoms/CustomAlert";
import Popup from "../../../utils/popup";
import BarcodeBody from "../../../components/organisms/BarcodeBody";
import IconWithLabel from "../../../components/atoms/IconWithLabel";
import IconData from "../../../static/IconData";
import { createShipments, getShipments } from "../../../routes/ApiEndPoints";
import { CommonConstants } from "../Constants/ShipmentConstants";
import { useUserContext } from "../../../routes/Contextlib";
import AccessControl from "../../../services/AccessControl";
import CommonServices from "../../../services/CommonServices";
import Archive from "../../../static/icons/Archive";
import Unarchive from "../../../static/icons/Unarchive";
import Button from "../../../components/atoms/Button";
import InputWithButton from "../../../components/molecules/InputWithButton";
import { CONSTANTS } from "../../../applicationConstants/AppConstants";
import FormValidation from "../../../services/FormValidation";

export const ManageShipment = () => {
  const [shipmentDetails, setShipmentDetails] = useState({});
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<"active" | "inactive">("active");
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [state, setState] = useState({
    archiveUnarchivePopup: false,
    selectedShipment: null,
    limit: CONSTANTS.PAGINATION_LIMIT,
    offset: 0,
    totalLength: 1,
    totalPages: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const [viewBarcode, setViewBarcode] = useState(false);
  type barcodeData = {
    title: string;
    barcodeId: string;
    barcodeUrl: string;
  };
  const { user } = useUserContext();
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [barcoodeData, setBarcoodeData] = useState<barcodeData>({
    title: "",
    barcodeId: "",
    barcodeUrl: "",
  });
  const includeactive = ['ready', 'in progress'];

  const bpDetails: any = JSON.parse(localStorage?.getItem("bp_details") ?? "");

  const savedPrinter = localStorage.getItem("savedPrinter") || ""; 


  const handleSortClick = async () => {
    if (Object.values(shipmentDetails).length > 0) {
      setCurrentPage(1);
      !isSorted && setIsSorted(true);
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      await fetchShipment('sort', sortOrder === "asc" ? "desc" : "asc");
    }
  };

  const handlePaginationClick = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const newOffset = (pageNumber - 1) * state.limit;
    setState((prevData) => ({
      ...prevData,
      offset: newOffset,
    }))
  }

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(storageMessage), 6000);
    }
  });

  useEffect(() => {
    fetchShipment();
  }, [state.offset, activeTab, searchText]);

  async function fetchShipment(filter?: any, order?: any) {
    try {
      let postData: any = {
        bpName: bpDetails?.title,
        brand_partner_id: bpDetails?.brand_partner_id,
        onlyshipments: true,
        limit: state.limit,
        offset: (filter === 'sort' ? 0 : state.offset),
        searchText: searchText,
        status: ((activeTab === 'active') ? includeactive : ['inactive'])
      };
      if (filter === 'sort' || isSorted) {
        postData.sort = order ?? sortOrder
      }
      const response = await APICALL.service(
        getShipments,
        "POST",
        postData
      );
      if (response.status === 200) {
        setShipmentDetails(response.data);
        const totalPages = Math.ceil(
          response.totalLength / state.limit
        );
        setState((prev) => ({ ...prev, 
          totalLength: response.totalLength, 
          totalPages: totalPages,
          offset: filter === 'sort' ? 0 : state.offset
        }))
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleViewBarcode = (title: any, id: string, url: string) => {
    setViewBarcode(true);
    setBarcoodeData((prevValues) => ({
      ...prevValues,
      title: title,
      barcodeId: id,
      barcodeUrl: url,
    }));
  };

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "shipment"
    );
    let permissions = [
      {
        permission: "shipment",
        read: true,
      },
    ];
    if (truePerm?.update === true || truePerm?.delete === true) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  const handleArchive = async () => {
    try {
      const shipment: any = state.selectedShipment;
      const publishStatus = shipment?.publish_status === 'inactive' ? 'ready' : 'inactive';

      const postdata = {
        archive: {
          shipment_id: shipment?.shipment_id,
          publish_status: publishStatus,
          user_id: user?.user_id,
          brand_partner_id: bpDetails?.brand_partner_id,
          bpName: bpDetails?.title,
          items: Number(shipment?.items)
        },
      };

      const response = await APICALL.service(createShipments, 'POST', postdata);
      if (response.status === 200) {
        setState((prev) => ({ ...prev, archiveUnarchivePopup: false, selectedShipment: null }));
        setActiveTab(activeTab === 'active' ? 'inactive' : 'active');
        setCurrentPage(1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = async (data: any) => {
    try {
      let shipment: any = Object.values(shipmentDetails)?.filter((item: any) => item.barcode_id == barcoodeData.barcodeId)?.[0];
      const postdata = {
        "printerName": data?.inputValue.trim(),
        "labelType": "LPN",
        "labelData": {
          "licensePlateNumber": barcoodeData.barcodeId,
          "clientId": bpDetails?.wms_bp_id,
          "shipmentDate": CommonServices.showDateBasedOnFormat(shipment?.dateof_delivery, 'dd/MM/yyyy'),
          "palletCount": shipment?.pallets,
          "cartonCount": shipment?.cartons,
          "printCount": data.printCount,
          "totalPrintCount": data.totalPrintCount
        }
      }
      let error: any = validatepostdata(postdata);
      if (!error) {
        let res: any = await CommonServices.printLabelApi(postdata);
        res?.message && customAlert(res?.type, t(res?.message), 6000);
        if (res.statusCode === 201) {
          localStorage.setItem("savedPrinter", postdata.printerName); 

          setViewBarcode(false);
          setBarcoodeData({ title: "", barcodeId: "", barcodeUrl: "", });
        }
      } else {
        let errortext = Object.keys(error).filter(key => error[key] !== "").map(key => `<div>${t(error[key])}</div>`).join("");
        errortext && customAlert('error', errortext, 6000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validatepostdata = (data: any) => {
    let error = {
      "printerName": FormValidation.nameValidation(data?.printerName, 255, 'Printer name'),
      "licensePlateNumber": FormValidation.nameValidation(data?.labelData?.licensePlateNumber, 8, 'License plate number'),
      "clientId": FormValidation.nameValidation(data?.labelData?.clientId, 255, 'ClientId'),
      "shipmentDate": FormValidation.dateValidation(data?.labelData?.shipmentDate, /^\d{2}\/\d{2}\/\d{4}$/, 'Shipment date'),
    }
    return Object.values(error)?.some(item => item !== "") ? error : false;
  }

  const handleTabChange = (tab: any) => {
    setState((prev) => ({ ...prev, offset: 0 }))
    setActiveTab(tab);
    setCurrentPage(1);
  }

  return (
    <AccessControl requiredPermissions={requiredPermissions} renderNoAccess={true}>
      <Layout pagename={t("Manage shipment")} logo={true} timer={false}>
        {/* title end*/}
        <div className="user-tabs my-3">
          <Button
            className={`${activeTab === "active" ? "active" : ""
              } border-0 bg-transparent tab-btn me-4`}
            handleClick={() => handleTabChange("active")}
            title={t("Active")}
          />
          <Button
            className={`${activeTab === "inactive" ? "active" : ""
              } border-0 bg-transparent tab-btn me-4`}
            handleClick={() => handleTabChange("inactive")}
            title={t("Inactive")}
          />
          <div className="mb-3 float-end">
            <InputWithButton
              handleClick={(searchText) => { setState((prev) => ({ ...prev, offset: 0, })); setSearchText(searchText); setCurrentPage(1); }}
              textFieldClass="form-control shadow-none"
              buttonClass="submit-btn shadow-none"
              noerror={true}
              buttonTitle={t('Search')}
              placeholder={t("Search by title")}
            />
          </div>
        </div>
        <div className="flex-1 overflow-auto shadow border rounded font_Brandon_medium ">
          <table className={`table my-2 ${styles.custable}`}>
            <thead>
              <tr>
                <th className="border-bottom-0 text-start">
                  {t("Shipment title")}
                  <button
                    onClick={handleSortClick}
                    className="sort-btn border-0 bg-transparent text-gray"
                  >
                    {sortOrder === "asc" ? <FaSort /> : <FaSort />}
                  </button>
                </th>
                <th className="border-bottom-0 ">
                  {t("Items")}
                </th>
                <th className="border-bottom-0 text-start">
                  {t("Comments")}
                </th>
                <th className="border-bottom-0 text-center table_actions">
                  {t("Actions")}
                </th>
              </tr>
            </thead>
            {Object.values(shipmentDetails).length === 0 ?
              <div className="text-danger">
                {t("No result found")}
              </div>
              :
              <tbody>
                {shipmentDetails && Object.values(shipmentDetails).map((item: any) => {
                  return (
                    <tr className="border rounded-1" key={item.shipment_id}>
                      <td className="text-break py-3 text-start">
                        <div className={`${styles.shipment_title}`}>
                          {item.title}
                        </div>
                        <div className="font16px">
                          {CommonServices.showDateBasedOnFormat(item.dateof_delivery, user?.date_format ?? 'dd-MM-yyyy')}
                        </div>
                        {/* <div className="font16px">Home goods</div> */}
                      </td>
                      <td className="text-break">{item.items}</td>
                      <td className="text-break py-3 text-start">
                        <div className="d-flex justify-content-between">
                          <span
                            className={`${styles.comment_block}`}
                            title={item.comment}
                          >
                            {item.comment}
                          </span>
                        </div>
                      </td>
                      <td className="text-break manage-users-action text-center align-baseline  py-3">
                        <div className="d-flex justify-content-center">
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "shipment",
                                update: true,
                              },
                            ]}
                          >
                            <span
                              className={`px-3 cursor-pointer ${styles.printbarcodeicon}`}
                              title={t("Edit")}
                              onClick={() => navigate(`/shipment/create/?edit=${item.shipment_id}`)}
                            >
                              <Dedit />{" "}
                            </span>
                            {includeactive.includes(item?.publish_status) && (
                              <span
                                className={`px-3 cursor-pointer ${item.publish_status !== 'ready' ? 'disabled' : ''} ${styles.printbarcodeicon}`}
                                title={t("Archive")}
                                onClick={() => {
                                  if (item.publish_status === 'ready') {
                                    setState((prev) => ({ ...prev, selectedShipment: item, archiveUnarchivePopup: true }));
                                  } else {
                                    customAlert('error', t("This shipment is in progress"), 6000);
                                  }
                                }}
                              >
                                <Archive /> {" "}
                              </span>
                            )}
                            {item.publish_status === 'inactive' && <span
                              className={`px-3 cursor-pointer ${styles.printbarcodeicon}`}
                              title={t("Unarchive")}
                              onClick={() => setState((prev) => ({ ...prev, selectedShipment: item, archiveUnarchivePopup: true }))}
                            >
                              <Unarchive /> {" "}
                            </span>}
                          </AccessControl>
                          {item.publish_status !== 'inactive' && <span
                            className={`px-3 cursor-pointer  ${styles.printbarcodeicon}`}
                            title={t("View workflow")}
                            onClick={() => navigate(`/shipment-dashboard/?edit=${item.shipment_id}`)}
                          >
                            {IconData.Openeye}{" "}
                          </span>}
                          <span
                            className="cursor-pointer px-3"
                            onClick={() =>
                              handleViewBarcode(
                                item.title,
                                item.barcode_id,
                                item.barcode_url
                              )
                            }
                          >
                            <IconWithLabel
                              classname1={`${styles.printbarcodeicon}`}
                              hoverText={t("Print barcode")}
                              svgData={IconData.PrinterIcon}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>}
          </table>
        </div>
        {/* buttons */}
        <div className="position-relative w-100 row mt-2 m-0">
          {state.totalLength > state.limit && state.totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={state.totalPages}
              handlePaginationClick={handlePaginationClick}
            />
          )}
          <div className="col-6 p-0">
            <button
              className="back-btn shadow-none "
              onClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
            >
              {t(CommonConstants.BACK)}
            </button>
          </div>
        </div>

        {viewBarcode && (
          <Popup
            body={
              <BarcodeBody
                handlePrint={handlePrint}
                savedPrinter={savedPrinter}

              />
            }
            title={t("Print barcode")}
            cancel={() => { setViewBarcode(false); setBarcoodeData({ title: "", barcodeId: "", barcodeUrl: "", }) }}
          />
        )}
        {state.archiveUnarchivePopup && (
          <Popup
            title={t("Confirmation")}
            body={t(
              `Are you sure you want to ${activeTab === "inactive" ? "activate" : "deactivate"
              } this shipment?`
            )}
            cancel={() =>
              setState((prev) => ({ ...prev, archiveUnarchivePopup: false, selectedShipment: null }))
            }
            submit={handleArchive}
            yestext={t(CommonConstants.YES)}
            notext={t(CommonConstants.NO)}
          />
        )}
      </Layout>
    </AccessControl>
  );
};
