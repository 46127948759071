import React, { useState, useEffect, useRef } from "react";
import styles from "../../../pages/Shipment/shimpment.module.css";
import Layout from "../../../pages/Shipment/Layout";
import { APICALL } from "../../../services/ApiServices";
import { fetchList, fetchBpwiseList, fetchMerchracks } from "../../../routes/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import { ConfigConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";
import { t } from "../../../pages/Translations/TranslationUtils";
import Card from "../../molecules/ShipmentDashboardCard";
import IconData from "../../../static/IconData";
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import { useUserContext } from "../../../routes/Contextlib";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import { CommonConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../services/CommonServices";
import useUpdateInactivityTimeoutService from "../../../services/useUpdateInactivityTimeoutService";
import { useInactivityTimeoutContext } from "../../../services/useInactivityTimeoutContext";
import ScreenDisable from "../../../utils/ScreenDisable";


// Define a type for the MerchRack item
type MerchRackItems = {
  value: number;
  label: string;
};

const PrePublishOrganism = () => {
  const navigate = useNavigate();
  const localdata = CommonShipmentServices.getPostData();
  const { user } = useUserContext();
  const [trackTime, setTrackTime] = useState<boolean>(false);
  const { updateTimeout } = useUpdateInactivityTimeoutService();
  const { inactivityTimeoutState } = useInactivityTimeoutContext();

  const [state, setState] = useState({
    merchRack: [] as any[],
    data: [] as any,
    selectedDataEntryIds: [] as any[],
    selectedMerchRackDataEntries: null as any,
    selctedMerchRackTitle: "",
    showPopup: false,
    selectAllItems: false,
    totaltime: 0,
    timerrunning: true,
    activetab: "notpublished",
    selectedmerchrack: null as any,
  });
  const [currentTime, setCurrentTime] = useState(0)
  const [prevTime, setPrevTime] = useState(0);

  // Set track time based on localStorage on component mount
  useEffect(() => {
    setTrackTime(localStorage.getItem("trackTime") && localStorage.getItem("trackTime") == "yes" ? true : false)
  }, [localStorage.getItem("trackTime")]);

  // Update timeout when track time changes
  useEffect(() => {
    if (trackTime) {
      updateTimeout("publishing");
    }
  }, [trackTime]);

  const handleTimeoutNavigation = async () => {
    await timeTracker(false, Number(inactivityTimeoutState.redirectTime));
    navigate(inactivityTimeoutState.redirectUrl);
  };

  useEffect(() => {
    if (inactivityTimeoutState.timeoutRedirection) {
      handleTimeoutNavigation();
    }
  }, [inactivityTimeoutState.timeoutRedirection]);

  useEffect(() => {
    if (user != null) {
      fetchMerchrackData();
    }
  }, [user != null]);

  useEffect(() => {
    if (user != null && trackTime) {
      timeTracker(true);

      window.addEventListener('beforeunload', () => timeTracker());

      return () => {
        timeTracker();
        window.removeEventListener('beforeunload', () => timeTracker());
      };
    }
  }, [user != null, trackTime]);

  const timeTracker = async (reloaded: boolean = false, timeoutRedirection: number | null = null) => {
    try {
      let time = await CommonShipmentServices.saveTimerApi(
        'bp',
        localdata?.brand_partner_id,
        user?.user_id,
        WorkflowStageNames.PREPUBLISH,
        reloaded,
        false,
        timeoutRedirection
      );
    }
    catch (error) {

    }
  }



  const handleMerchRack = async (item: any) => {
    if (state.timerrunning) {
      localStorage.setItem("prepublishTime", currentTime.toString());
      // Navigate to the merchrack page
      navigate(`/publish/rack?id=${item.merch_rack_id}&tab=active`);
    }
  };

  const fetchMerchrackData = async () => {
    try {
      const postData = {
        method: "POST",
        data: {
          list: [
            ConfigConstants.ALL_BPWISE_MERCH_RACK,
          ],
          bp_id: [localdata?.brand_partner_id],
        },
      };
      const response = await APICALL.service(
        fetchMerchracks,
        "POST",
        postData
      );
      setState((prevState) => ({ ...prevState, merchRack: CommonServices.sortAlphabattically(response, 'label') }));
    } catch (error) {
      console.error(error);
    }
  };
  
  const setTimerRunning = (timer: boolean) => {
    setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
  }

  useEffect(() => {
    if (localStorage.getItem("prepublishTime") != null) {
      const timer = Number(localStorage.getItem("prepublishTime"))
      setPrevTime(timer)
      localStorage.removeItem("prepublishTime")
    }
  }, [])

  return (

    <Layout
      pagename="Publishing"
      logo={true}
      timer={true}
      ongoing={trackTime}
      totaltime={prevTime}
      stage={WorkflowStageNames.PREPUBLISH}
      setTimerRunning={setTimerRunning}
      setCurrentTime={setCurrentTime}
    >
      <div className="flex-1 overflow-auto">
        <div className="h-100 overflow-auto border rounded shadow">
          {state.selectedmerchrack == null &&
            <div className="row p-4 position-relative m-0">
              {state.merchRack.map((item) => (
                <div className={`${styles.prepublish_card_block} p-3 `}>
                  <ScreenDisable display="none" />
                  <Card
                    key={item?.merch_rack_id}
                    title={item?.label}
                    icon={IconData.merchrack}
                    className={`${styles.prepublish_card} border rounded px-5 p-4`}
                    handleOnclick={() => handleMerchRack(item)}
                    // smallCard={true}
                    main_class="w-100 cursor-pointer"
                    count={item?.item_count}
                  />
                </div>))}
            </div>
          }
        </div>
      </div>
      <div className="">
        <ActionButtonGroup
          backTitle={t(CommonConstants.BACK)}
          handleBackClick={() => navigate('/shipment-dashboard')}
        />
      </div>
    </Layout>
  );
};

export default PrePublishOrganism;
