import React, { useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import {
  FetchLaundry,
  createLaundry,
} from "../../../routes/ApiEndPoints";
import Layout from "../../../pages/Shipment/Layout";
import { useNavigate } from "react-router-dom";
import { t } from "../../../pages/Translations/TranslationUtils";
import AdminCard from "../../molecules/AdminDashBoardCard";
import Popup from "../../../utils/popup";
import StainTreatmentPopupBody from "../../molecules/StainTreatmentPopupBody";
import { CommonConstants, LaundryInternalStages } from "../../../pages/Shipment/Constants/ShipmentConstants";
import ShipmentDashboardCard from "../../molecules/ShipmentDashboardCard";
import styles from "../../../pages/Shipment/shimpment.module.css";
import IconData from "../../../static/IconData";
import { Add } from "../../../static/icons/shipment/Add";
import TimerCountDown from "../../atoms/TimerCountDown";
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import ScreenDisable from "../../../utils/ScreenDisable";
import { useUserContext } from "../../../routes/Contextlib";
import useUpdateInactivityTimeoutService from "../../../services/useUpdateInactivityTimeoutService";
import { useInactivityTimeoutContext } from "../../../services/useInactivityTimeoutContext";

const WashingOrganism: React.FC = () => {
  type State = {
    stage_id: any;
    item: number;
    time: number;
    totaltime: number;
    timerrunning: boolean;
  };

  const InitialState: State = {
    stage_id: null,
    item: 0,
    time: 0,
    totaltime: 0,
    timerrunning: true
  };
  interface FormErrors {
    items: any;
    time: any;
    moveItem: any;
    general: any;
    pending: any;
  }
  const { user } = useUserContext();
  const localdata = CommonShipmentServices.getPostData();
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState<State>(InitialState);
  const [laundryData, setLaundryData] = useState<any>([]);
  const [movePopup, setMovePopup] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({
    items: null,
    time: null,
    moveItem: null,
    general: null,
    pending: null,
  });
  const [refreshLaundry, setRefreshLaundry] = useState<boolean>(false);
  const [selectedStage, setSelectedStage] = useState<any>();
  const [selectedMoveStage, setSelectedMoveStage] = useState<any>();
  const [itemsToMove, setItemsToMove] = useState<any>();
  const [pendingItems, setPendingItems] = useState<number>(0);
  const [itemsUpperLimit, setItemsUpperLimit] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { updateTimeout } = useUpdateInactivityTimeoutService();
  const { inactivityTimeoutState } = useInactivityTimeoutContext();
  const [trackTime, setTrackTime] = useState<boolean>(false)
  
  useEffect(() => {
    setTrackTime(localStorage.getItem("trackTime") && localStorage.getItem("trackTime") == "yes" ? true : false)
  }, [localStorage.getItem("trackTime")]);

  useEffect(() => {
    if (trackTime) {
      updateTimeout("internal_laundry")
    }
  }, [trackTime]);

  const handleTimeoutNavigation = async () => {
    await timeTracker(false, Number(inactivityTimeoutState.redirectTime));
    navigate(inactivityTimeoutState.redirectUrl);
  };

  useEffect(() => {
    if (inactivityTimeoutState.timeoutRedirection) {
      handleTimeoutNavigation()
    }
  }, [inactivityTimeoutState.timeoutRedirection]);

  useEffect(() => {
    setLaundryData([]);
    const fetchData = async () => {
      const postData = {
        ...localdata
      };
      try {
        const laundrydata = await fetchLaundryData(postData);
        setLaundryData(laundrydata.data);
        setTotalCount(laundrydata.stagecount);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [refreshLaundry]);

  const fetchLaundryData = async (postData: any) => {
    try {
      const response = await APICALL.service(FetchLaundry, "POST", postData);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardClick = async (item: any) => {
    try {
      if (state.timerrunning) {
        const pending = await checkPendingItems(item.stage_id);

        if (pending > 0) {
          setPendingItems(pending);
          setState((prevState) => ({
            ...prevState,
            item: pending,
          }));
          setErrors((prev) => ({
            ...prev,
            pending: t('There are') + ' ' + pending + ' ' + t('items in queue, please add time'),
          }));
        } else {
          setPendingItems(0);
          setErrors((prev) => ({ ...prev, pending: null }));
        }
        const remainigItems = await calculateRemainingItemsCount();

        setItemsUpperLimit(remainigItems);

        if (remainigItems > 0) {
          setErrors((prev) => ({ ...prev, items: null }));
        } else {
          setErrors((prev) => ({ ...prev, items: pending > 0 ? null : t("No items available to add") }));
        }
        setState((prevState) => ({
          ...prevState,
          stage_id: item.stage_id,
        }));
        setPopup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkPendingItems = async (stage_id: any) => {
    const pending = laundryData?.filter(
      (item: any) =>
        item.stage_id == stage_id &&
        item.start_time == null &&
        item.type === "washer"
    );
    const count = pending[0]?.items;
    return count;
  };

  const calculateRemainingItemsCount = async () => {
    const activeCount = laundryData?.reduce((total: any, item: any) => {
      if (item.finish_status === false) {
        return total + item.items;
      } else {
        return total;
      }
    }, 0);

    const remainigItems = totalCount - activeCount;
    return remainigItems;
  };

  const handleItemsAndTimeChange = async (value: any, type: string) => {
    if (type === "item" && value > itemsUpperLimit) {
      setErrors((prev) => ({
        ...prev,
        items: t("Number exceeds available counts"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, items: null }));
      setState((prevState) => ({
        ...prevState,
        [type]: value,
      }));
    }
  };

  const saveLaundry = () => {
    if (state.item == 0) {
      setErrors((prev) => ({ ...prev, items: t("Please select items") }));
    }
    if (state.item > 0 && errors.items === null) {
      setErrors((prev) => ({ ...prev, items: null }));
      createInternalLaundry({
        ...localdata,
        stage_id: state.stage_id,
        type: "washer",
        items: pendingItems ? 0 : state.item,
        time: state.time,
        start_time: new Date(),
        end_time: new Date().setMinutes(
          new Date().getMinutes() + Number(state.time)
        ),
        finish_status: false,
        created_by: user?.user_id ?? "",
      });
    }
  };

  const createInternalLaundry = async (postData: any) => {
    try {
      const response = await APICALL.service(createLaundry, "POST", postData);

      if (response?.status === 200) {
        setSelectedMoveStage("");
        setSelectedStage("");
        setPopup(false);
        setRefreshLaundry(!refreshLaundry);
        setMovePopup(false);
        setState((prevState) => ({
          ...prevState,
          stage_id: null,
          item: 0,
          time: 0,
        }));
        setSelectedMoveStage('')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStageWiseCount = (stage_id: any) => {
    let count = 0;
    if (laundryData.length > 0) {
      count = laundryData?.reduce((total: any, item: any) => {
        if (
          item.finish_status === false &&
          item.stage_id == stage_id &&
          item.type === "washer"
        ) {
          return total + item.items;
        } else {
          return total;
        }
      }, 0);
    }
    return count;
  };

  const getStageWiseTime = (stage_id: any) => {
    let time = 0;
    const current_time = new Date().getTime();

    if (laundryData.length > 0) {
      time = laundryData?.reduce((total: any, item: any) => {
        if (
          item.finish_status === false &&
          item.stage_id == stage_id &&
          item.type === "washer" &&
          new Date(item.end_time) > new Date()
        ) {
          return total + (new Date(item.end_time).getTime() - current_time);
        } else {
          return total;
        }
      }, 0);
    }
    const totalRemainingTimeInSeconds = Math.floor(time / 1000);
    return totalRemainingTimeInSeconds;
  };

  const readyToTransferItemsCount = (stage_id: any) => {
    let count = 0;
    if (laundryData.length > 0) {
      count = laundryData?.reduce((total: any, item: any) => {
        if (
          item.finish_status === false &&
          item.stage_id == stage_id &&
          new Date(item.end_time) < new Date() &&
          item.start_time != null &&
          item.type === "washer"
        ) {
          return total + item.items;
        } else {
          return total;
        }
      }, 0);
    }
    return count;
  };

  const handleTranferClick = (item: any) => {
    if (state.timerrunning) {
      setSelectedStage(item.stage_id);
      const count = readyToTransferItemsCount(item.stage_id);
      setItemsToMove(count);

      if (count > 0) {
        setErrors((prev) => ({ ...prev, moveItem: null }));
      } else {
        setErrors((prev) => ({ ...prev, moveItem: t("No items to move") }));
      }
      setMovePopup(true);
    }
  };

  const prepareItemsToMove = (item: any) => {
    setErrors((prev) => ({
      ...prev,
      general: null,
    }));
    setSelectedMoveStage(item.stage_id);
    setState((prevState) => ({
      ...prevState,
      item: itemsToMove,
      stage_id: item.stage_id,
    }));
  };

  const moveItems = () => {
    if (selectedMoveStage) {
      createInternalLaundry({
        ...localdata,
        stage_id: state.stage_id,
        start_time: null,
        end_time: null,
        time: null,
        type: "washer",
        items: state.item,
        finish_status: false,
        created_by: user?.user_id ?? "",
        update_previous: selectedStage,
        previous_type: "washer",
      });
    } else {
      setErrors((prev) => ({
        ...prev,
        general: t("Please select a stage to move"),
      }));
    }
  };

  const timeTracker = async (reloaded: boolean = false, timeoutRedirection: number | null = null) => {
    let time = await CommonShipmentServices.saveTimerApi(
      "bp",
      localdata?.brand_partner_id,
      user?.user_id,
      WorkflowStageNames.INTERNALLAUNDRY,
      reloaded,
      false,
      timeoutRedirection
    );
  };

  useEffect(() => {
    if (user && trackTime) {
      timeTracker(true);

      window.addEventListener('beforeunload', () => timeTracker());
      return () => {
        timeTracker();
        window.removeEventListener('beforeunload', () => timeTracker());
      }
    }
  }, [user, trackTime]);
  const setTimerRunning = (timer: boolean) => {
    setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
  }

  return (
    <Layout
      pagename={t("Washing")}
      logo={true}
      timer={true}
      ongoing={trackTime}
      stage={WorkflowStageNames.INTERNALLAUNDRY}
      // totaltime={state.totaltime}
      setTimerRunning={setTimerRunning}
    >
      {/* title end*/}
      <div className={`d-flex flex-column h-100 ${styles.strain_body}`}>
        <div className="p-4 flex-1 overflow-auto shadow border rounded font_Brandon_medium position-relative">
          <ScreenDisable display="none" />
          <div className="flex-1 h-100 overflow-auto">
            <div className="d-flex flex-wrap max-content-height">
              {LaundryInternalStages?.filter(
                (item: any) =>
                  item.type !== "stain_treatment" &&
                  item.stage_name !== "data_entry"
              ).map((item, index) => {
                return (
                  <div className={`d-flex flex-column align-items-center ${styles.shipment_card_main_box}`}>
                    <ShipmentDashboardCard
                      key={index}
                      title={t(item?.title)}
                      text={getStageWiseCount(item.stage_id)}
                      text1={
                        <TimerCountDown
                          initialTime={getStageWiseTime(item.stage_id)}
                        />
                      }
                      handleCircleClick={() => handleTranferClick(item)}
                      className={`${styles.box} ${styles.washingicon} border rounded text-break px-1`}
                      icon={item.icon}
                      count={IconData.Transfericon}
                      iconclass={styles.washingicon}
                      main_class={`flex-1 w-100 h-100 `}
                    />
                    <div className="mt-4">
                      <span className="hoversvg cursor-pointer" onClick={() => handleCardClick(item)}>
                        <Add />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <button
            className="back-btn shadow-none "
            onClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
          >
            {t(CommonConstants.BACK)}
          </button>
        </div>
      </div>
      {popup && (
        <Popup
          title={t("Add items")}
          body={
            pendingItems == 0 && itemsUpperLimit == 0 ? (
              <span>{errors.items}</span>
            ) : (
              <StainTreatmentPopupBody
                item={state.item}
                handleChange={handleItemsAndTimeChange}
                timer={true}
                time={state.time}
                errors={errors.pending ?? errors.items}
                pendingItems={pendingItems}
                upperLimit={itemsUpperLimit}
              />
            )
          }
          modalSize="xl"
          yestext={errors.items ? "" : t(CommonConstants.SAVE)}
          notext={t(CommonConstants.BACK)}
          submit={() => saveLaundry()}
          cancel={() => {
            setPopup(false),
              setState((prev) => ({
                ...prev,
                stage_id: null,
                item: 0,
                time: 0,
              }));
          }}
        />
      )}
      {movePopup && (
        <Popup
          title={t("Move items")}
          body={
            <div className="d-flex flex-wrap justify-content-center internal_laundary_move_item_body">
              {errors.moveItem
                ? errors.moveItem
                : LaundryInternalStages?.filter(
                  (item: any) =>
                    item.type !== "stain_treatment" &&
                    item.stage_id !== selectedStage
                ).map((item, index) => {
                  return (
                    <AdminCard
                      key={index}
                      title={t(item?.title)}
                      handleClick={() => prepareItemsToMove(item)}
                      text1=""
                      icon={item.icon}
                      selected={
                        item.stage_id == selectedMoveStage
                          ? styles.move_selected
                          : ""
                      }
                    />
                  );
                })}
              <span>{errors.general}</span>
            </div>
          }
          modalclass={errors.moveItem ? "lg" : "transfer_to_modal"}
          yestext={errors.moveItem ? "" : t(CommonConstants.SAVE)}
          notext={t(CommonConstants.BACK)}
          submit={() => moveItems()}
          cancel={() => {
            setMovePopup(false), setSelectedMoveStage(null);
          }}
        />
      )}
    </Layout>
  );
};

export default WashingOrganism;
