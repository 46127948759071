import React, { useRef, useState, useEffect } from "react";
import { t } from "../Translations/TranslationUtils";
import CustomAlert from "../../components/atoms/CustomAlert";
import styles from "../MediaLibrary/MediaLibrary.module.css";
import { Upload } from "../../static/icons/Upload";
import { APICALL } from "../../services/ApiServices";
import Pagination from "../../services/Paginations";
import * as XLSX from "xlsx";
import Popup from "../../utils/popup";
import {
	importProductCatalogue,
	fetchProductCatalogue,
	fetchAllBrandPartnerUrl,
	updateCatalogue,
	clearCatalogue,
	wmsimportcatalogue,
} from "../../routes/ApiEndPoints";
import AccessControl from "../../services/AccessControl";
import TableStructure from "../../components/atoms/TableStructure";
import {
	CommonConstants,
	ProductCatalogueConstants,
} from "../Shipment/Constants/ShipmentConstants";
import { useNavigate } from "react-router-dom";
import InputWithButton from "../../components/molecules/InputWithButton";
import { CONSTANTS } from "../../applicationConstants/AppConstants";
import ShipmentReportsFilterBody from "../../components/molecules/ShipmentReportsFilterBody";
import Button from "../../components/atoms/Button";
import customAlert from "../../components/atoms/CustomAlert";
import CommonServices from "../../services/CommonServices";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import { FolderIcon } from "../../static/icons/FolderIcon";
import InputTextfield from "../../components/atoms/InputTextField";
import PublishListPopup from "../../components/organisms/PrePublish/PublishListPopup";
import IconData from "../../static/IconData";
import IconWithLabel from "../../components/atoms/IconWithLabel";
import DownloadXlsx from "../../services/DownloadXlsx";
import FormValidation from "../../services/FormValidation";
import EncryptDecryptService from "../../services/EncryptDecryptService";

const ProductCatalogue: React.FC = () => {
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const searchParams = new URLSearchParams(location.search);
	const urlBpid = searchParams.get('bpid') ?? '';
	const bpId = Number(EncryptDecryptService.decryptData(urlBpid));
	const tab = urlParams.get("tab") ?? "";
	const brand = JSON.parse(localStorage.getItem('catalogue_bp') ?? '{}');
	const currentPage = localStorage.getItem('current_page') ?? '';
	const [catalogue, setCatalogue] = useState<any[]>([]);
	const [brandpartners, setBrandPartners] = useState<any>();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [selectedColumns, setSelectedColumns] = useState<string[]>([
		"style_number",
		"sku_number",
		"upc",
		"brand_partner_title",
		"product_description",
		"product_type",
		"product_group",
		"gender",
		"currency",
	]);
	const [state, setState] = useState({
		showPopup: false,
		filterPopup: false,
		filteredTableHeaders: [{}],
		filteredTableValues: [{}],
		importedrejecteditems: {
			view: false,
			rejected: 0,
		}
	});
	const [popup, setPopup] = useState(false);
	const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
	const [acceptPopup, setAcceptPopup] = useState({ popUp: false, catalogue_id: null });
	const [archiveState, setArchiveState] = useState({ archive: "", id: "" });
	const [filterobj, setFilterObj] = useState<any>({
		search: "",
		limit: CONSTANTS.PAGINATION_LIMIT,
		brand_partner_id: null,
		offset: 0,
		status: "active",
	});
	const [stateobj, setStateObj] = useState<any>({
		currentpage: 1,
		totalpages: 1,
		count: 0,
		searchtext: ''
	});
	const fileHeaders = [
		'style_number', 'sku_number', 'brand_partner', 'product_type', 'gender',
		'country', 'msrp', 'color_name', 'hex_color_code', 'product_description',
		'product_group', 'currency', 'renewal_fee', 'size', 'weight', 'fabric_content', 'upc'
	];
	const [clearCataloguePopup, setClearCataloguePopup] = useState<boolean>(false)
	const filterValues = [
		{ value: 1, title: t("Style number"), handle: "style_number" },
		{ value: 2, title: t("Brand partner"), handle: "brand_partner_title" },
		{ value: 3, title: t("Product type"), handle: "product_type" },
		{ value: 4, title: t("Gender"), handle: "gender" },
		{ value: 5, title: t("Country"), handle: "country" },
		{ value: 6, title: t("MSRP"), handle: "msrp" },
		{ value: 7, title: t("Color name"), handle: "color_name" },
		{ value: 8, title: t("Hex color code"), handle: "hex_color_code" },
		{ value: 9, title: t("Product description"), handle: "product_description", },
		{ value: 10, title: t("Product group"), handle: "product_group" },
		{ value: 11, title: t("Currency"), handle: "currency" },
		{ value: 12, title: t("Renewal fee"), handle: "renewal_fee" },
		{ value: 13, title: t("Size"), handle: "size" },
		{ value: 14, title: t("Weight"), handle: "weight" },
		{ value: 15, title: t("Fabric content"), handle: "fabric_content" },
	];

	useEffect(() => {
		// !brandpartners && !tab && !filterobj.brand_partner_id && getBrandPartners();
		filterobj.brand_partner_id && getProductCatalogue();
	}, [filterobj]);

	const filteredBrandPartners = brandpartners?.filter((brandpartner: any) =>
		brandpartner.title.toLowerCase().includes(stateobj.searchtext.toLowerCase())
	);

	useEffect(() => {
		if (brand) {
			getBrandPartners();
			setFilterObj((prevState: any) => ({
				...prevState,
				brand_partner_id: bpId
			}));
		}
		if (tab !== null && tab !== '') {
			setFilterObj((prev: any) => ({ ...prev, status: tab, offset: 0 }));
			const url = new URL(window.location.href);
			url.searchParams.delete('tab');
			window.history.pushState(null, '', url.href);
		}
	}, []);

	useEffect(() => {
		const successMessage: any =
			localStorage?.getItem("successAlertMessage") ?? null;
		const errorMessage: any = localStorage?.getItem("errorAlertMessage") ?? "";
		localStorage.removeItem("successAlertMessage");
		if (successMessage) {
			customAlert("success", t(successMessage), 2000);
		} else if (errorMessage) {
			customAlert("error", t(errorMessage), 2000);
		}
	});

	useEffect(() => {
		const filteredHeaders =
			ProductCatalogueConstants.PRODUCT_CATALGUE_TABLE_HEADERS.filter(
				(header) => {
					return (
						selectedColumns.includes(header.handle) || header.handle === "sku_number"
					);
				}
			);
		const filteredValues =
			ProductCatalogueConstants.DATA_ENTRY_TABLE_VALUES.filter((value) => {
				return selectedColumns.includes(value) || value === "sku_number";
			});
		setState((prev) => ({ ...prev, filteredTableHeaders: filteredHeaders }));
		setState((prev) => ({ ...prev, filteredTableValues: filteredValues }));
	}, [selectedColumns]);

	async function getProductCatalogue() {
		try {
			let postdata = {
				method: "POST",
				data: {
					...filterobj,
				},
			};
			const response = await APICALL.service(
				fetchProductCatalogue,
				"POST",
				postdata,
				true
			);
			if (response?.status == 200 && response?.data) {
				let result = response?.data?.data?.map((item: any) => ({
					...item,
					checked: false
				}));
				setCatalogue(result);
				getTotalPagesCount(response?.data?.total_count);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const handleTabClick = (status: string) => {
		setStateObj((stateobj: any) => ({ ...stateobj, currentpage: 1 }));
		setFilterObj((filterobj: any) => ({
			...filterobj,
			offset: 0,
			status: status,
		}));
	};

	const getBrandPartners = async () => {
		const postData = {
			method: "POST",
			data: { issaved: [true] },
		};
		const response = await APICALL.service(
			fetchAllBrandPartnerUrl,
			"POST",
			postData,
			true
		);
		if (response.status === 200) {
			const matchingBrandPartners = response?.data?.filter(
				(brand: any) => brand.clone_id === brand.brand_partner_id
			) ?? [];
			
			const sortedBrandPartners = CommonServices.sortAlphabattically(matchingBrandPartners);
			setBrandPartners(sortedBrandPartners);
			localStorage.removeItem('catalogue_bp');
		}
	};

	const getTotalPagesCount = (totalcount: number) => {
		const totalpages = Math.ceil(totalcount / CONSTANTS.PAGINATION_LIMIT);
		setStateObj((prevState: any) => ({
			...prevState,
			count: totalcount,
			totalpages: totalpages,
		}));
	};

	const handleFileUpload = async (data: any, headerRow: any, wms_import = false) => {
		let postdata = {
			method: "POST",
			data: {
				data: data,
				headerRow: headerRow,
				brand_partner_id: filterobj?.brand_partner_id,
				wms_import: wms_import
			}
		}
		let response = await APICALL.service(importProductCatalogue, "POST", postdata, true);
		if (response != null && response.status == 200 && response.data != null) {
			setStateObj((prevState: any) => ({
				...prevState,
				currentpage: 1,
			}));
			setCatalogue(response?.data?.data);
			if (response.count > 0) {
				setState(prev => ({
					...prev,
					importedrejecteditems: { ...prev.importedrejecteditems, view: true, rejected: response.count }
				}));
			} else {
				setFilterObj((prev: any) => ({ ...prev, status: 'active', offset: 0 }));
			}
			getTotalPagesCount(response?.data?.total_count);
			setSelectAllCheckbox(false)
		}
		let status = response && response.status == 200 ? "success" : "error";
		let message = response && response.message ? response.message : "";
		CustomAlert(status, t(message ?? ""), 6000);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	}

	const validateData = (actualHeaders: string[], requiredHeaders: string[], headerRow?: any): any => {
		if (!actualHeaders || actualHeaders?.length === 0) {
			return t('No data found in the file');
		}
		if (!headerRow) {
			if (actualHeaders.length != requiredHeaders.length) {
				return t('One or more header column is empty or invalid');
			} else if (!requiredHeaders.every(header => header !== null && actualHeaders.includes(header))) {
				return t('Please upload an Excel file with valid and required headers');
			}
		} else {
			let skuindex = headerRow.indexOf('sku_number');
			let upcindex = headerRow.indexOf('upc');
			if (actualHeaders.some((item) => !item[skuindex])) {
				return t('Sku number is missing in some of the rows');
			}
			if (actualHeaders.some((item) => !item[upcindex])) {
				return t('UPC number is missing in some of the rows');
			}
			return lengthValidation(actualHeaders, headerRow);
		}
		return false;
	};

	const lengthValidation = (actualHeaders: string[], headerRow: any) => {
		const columns = [
			{ name: 'sku_number', maxLength: 50 },
			{ name: 'upc', maxLength: 50 },
			{ name: 'product_description', maxLength: 80 },
			{ name: 'product_group', maxLength: 10 },
			{ name: 'style_number', maxLength: 15 },
			{ name: 'size', maxLength: 10 },
			{ name: 'color_name', maxLength: 20 },
			{ name: 'hex_color_code', maxLength: 7 },
		];

		let skuindex = headerRow.indexOf('sku_number');
		const errorsku = [
			{
				type: 'maximum_hex_code',
				error: `Only ${CONSTANTS.COLORS} hex color codes are allowed`,
				sku: [] as any[]
			},
			{
				type: 'length',
				error: `Length exceeds the maximum`,
				sku: [] as any[]
			},
			{
				type: 'duplicate_hex_code',
				error: `Some of the hex color codes appear more than once within a single color`,
				sku: [] as any[]
			},
			{
				type: 'invalid_hex_code',
				error: `Some of the hex color codes are invalid`,
				sku: [] as any[]
			},
			{
				type: 'invalid_data',
				error: `Some of the rows has invalid data`,
				sku: [] as any[]
			}
		];

		columns.forEach((col) => {
			let index = headerRow.indexOf(col.name);
			if (index !== -1) {
				actualHeaders.forEach((item) => {
					let data = item[index]?.toString()?.trim();
					let errortype = '';
					if (col.name === 'hex_color_code') {
						let codes = CommonServices?.splitAndJoin(data, ';') ?? [];
						if (codes?.length > CONSTANTS.COLORS) {
							errortype = 'maximum_hex_code'
						} else {
							if (FormValidation?.hasDuplicates(codes)) {
								errortype = 'duplicate_hex_code';
							} else {
								let error = codes?.map((item: any) => FormValidation.isValidHexColor(item ?? ""));
								errortype = error?.some((item: any) => item != "") ? "invalid_hex_code" : '';
							}
						}
					} else if (col.name === 'upc') {
						let error = /^[0-9]+$/.test(item[index])
						errortype = error ? "invalid_data" : ""
					}
					else {
						if (data && data.length > col.maxLength) {
							errortype = 'length'
						}
					}
					if (errortype !== '') {
						errorsku?.find((err: any) => err.type === errortype)?.sku?.push(item[skuindex]);
					}
				});
			}
		});
		return constructError(errorsku);
	};

	const constructError = (errors: any) => {
		let iserror = errors.some((item: any) => item.sku.length !== 0);

		if (iserror) {
			return errors.map((item: any) => {
				let skulength = item.sku.length;
				if (skulength === 0) {
					return;
				}
				const skus = skulength > 9 ? '9+ SKU' : item.sku.join(', ');
				return `<div>${skus}: ${item.error}</div>`;
			}).filter(Boolean).join('\n');
		} else {
			return false;
		}
	}

	const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
		let headerRow: string[] = [];
		const file = event.target.files && event.target.files[0];

		if (file) {
			const fileExtension = file.name.split(".").pop();
			if (['xls', 'xlsx'].includes(fileExtension ?? "")) {
				const workbook = XLSX.read(await file.arrayBuffer(), { type: "array" });
				// Assuming you want to read data from the first sheet
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				// Parse the data
				const importedData: any = XLSX.utils.sheet_to_json(worksheet, {
					header: 1,
				});

				headerRow = importedData[0]?.map((header: any) => {
					return CommonServices.removeSpaceAndAddUnderscore(header ?? "");
				});
				let validate: any = validateData(headerRow, fileHeaders);
				if (validate) {
					CustomAlert('error', validate, 6000);
				} else {
					const filteredData = importedData.slice(1).filter((row: any) =>
						row.length > 0
					);
					validate = validateData(filteredData, fileHeaders, headerRow);
					if (!validate) {
						await handleFileUpload(filteredData, headerRow);
					} else {
						CustomAlert('error', validate, 6000);
					}
				}
			} else {
				CustomAlert('error', t("Please upload excel file"), 6000)
			}
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};
	const handleSearch = (search: any) => {
		if (filterobj.search != search) {
			setStateObj((stateobj: any) => ({
				...stateobj,
				currentpage: 1,
			}));
			setFilterObj((prevState: any) => ({
				...prevState,
				search: search,
				offset: 0,
			}));
		}
	};

	const handlePaginationClick = (pagenumber: number) => {
		const newoffset = (pagenumber - 1) * CONSTANTS.PAGINATION_LIMIT;
		setFilterObj((filterobj: any) => ({
			...filterobj,
			offset: newoffset,
		}));
		setStateObj((stateobj: any) => ({
			...stateobj,
			currentpage: pagenumber,
		}));
	};

	const handleEdit = (e: any) => {
		navigate(
			`/product-catalogue?edit=${e.product_catalogue_id}&status=${filterobj.status}`
		);
	};

	const handleArchivePopup = async (e: any) => {
		setPopup(true);
		const status = filterobj.status == 'inactive' ? "active" : "inactive";
		setArchiveState((prev) => ({
			...prev,
			archive: status,
			id: e.product_catalogue_id,
		}));
	};

	const handleAccepttPopup = async (e: any) => {
		setAcceptPopup((prev) => ({
			...prev, popUp: true, catalogue_id: e?.product_catalogue_id
		}))
	};

	useEffect(() => {
		setSelectAllCheckbox(false);
	}, [stateobj.currentpage])

	const handleCheckBox = async (e: any) => {
		let catalogueData: any;
		if (e.target.id === "select_all") {
			catalogueData = catalogue.map((item) => ({ ...item, checked: !selectAllCheckbox })); // Toggle all items
			setSelectAllCheckbox(!selectAllCheckbox); // Sync "Select All" checkbox
		} else {
			catalogueData = catalogue.map((item) =>
				item.product_catalogue_id == e.target.id
					? { ...item, checked: !item.checked }
					: item
			);
			// Check if all individual checkboxes are checked after update
			setSelectAllCheckbox(catalogueData?.every((item: any) => item.checked));
		}
		setCatalogue(catalogueData);
	};

	const handleAcceptSelectedCatalogue = async () => {
		if (catalogue?.some((item: any) => item.checked)) {
			setAcceptPopup((prev) => ({
				...prev, popUp: true
			}))
		} else {
			customAlert("error", t("Please select at lease one item"), 2000);
		}
	}

	const acceptCatalogue = async (catalogueId: any = null) => {
		const selectedCatalogues = catalogue
			.filter((item: any) => catalogueId != null ? catalogueId == item.product_catalogue_id : item.checked)
			.map((item: any) => [
				item.style_number,
				item.sku_number,
				item.brand_partner_title,
				item.product_type,
				item.gender,
				item.country,
				item.msrp,
				item.color_name,
				item.hex_color_code,
				item.product_description,
				item.product_group,
				item.currency,
				item.renewal_fee,
				item.size,
				item.weight,
				item.fabric_content,
				item.upc,
				item.product_catalogue_id
			]);
		handleFileUpload(selectedCatalogues, [...fileHeaders, "catalogue_id"], true)
		setAcceptPopup((prev) => ({ ...prev, popUp: false, catalogue_id: null }))
	};

	const handleArchive = async () => {
		const postData = {
			method: "POST",
			data: archiveState,
		};
		const response = await APICALL.service(
			updateCatalogue,
			"POST",
			postData,
			true
		);
		if (response?.status == 200) {
			CustomAlert("success", t(`Item ${filterobj.status == "inactive" ? "activated" : "archived"
				} successfully`), 6000);
			setFilterObj((prev: any) => ({
				...prev,
				status: filterobj.status == "active" ? "inactive" : "active",
			}));
		} else {
			CustomAlert("success", t(response?.message), 6000);
		}
		setPopup(false);
	};

	const handleBrandClick = (brand: any) => {
		setFilterObj((prev: any) => ({
			...prev,
			brand_partner_id: brand.brand_partner_id
		}))
		const stringBpid = String(brand.brand_partner_id);
		const encryptBpid = EncryptDecryptService.encryptData(stringBpid);
		navigate(`/product-catalogue/manage?bpid=${encryptBpid}`);
	}

	const handleBack = () => {
		if (!filterobj.brand_partner_id) {
			navigate("/application-configuration")
		} else {
			localStorage.removeItem('catalogue_bp');
			setStateObj((prev: any) => ({
				...prev,
				searchtext: ''
			}));
			setCatalogue([]);
			setFilterObj((prev: any) => ({
				...prev,
				status: 'active',
				brand_partner_id: null
			}));
			navigate(`/product-catalogue/manage`);
		}
	}

	const handlePopupSubmit = (action: boolean) => {
		action && setFilterObj((prev: any) => ({ ...prev, status: 'rejected' }));
		setState(prev => ({
			...prev,
			importedrejecteditems: { ...prev.importedrejecteditems, view: false, rejected: 0 }
		}));
	}

	const handleExportTemplate = async () => {
		const prepareData: any = fileHeaders.reduce((acc: any, item: any) => {
			acc[item] = "";
			return acc;
		}, {});
		DownloadXlsx([prepareData], 'Import catalogue template');
	}

	const clearCatalogueData = async () => {
		const postData = {
			method: "POST",
			data: {
				brand_partner_id: filterobj.brand_partner_id,
			},
		};
		const response = await APICALL.service(
			clearCatalogue,
			"POST",
			postData,
			true
		);
		if (response?.status == 200) {
			CustomAlert("success", t("Catalogue cleared successfully"), 6000);
			setFilterObj((prev: any) => ({
				...prev,
				status: 'active',
			}));
		} else {
			CustomAlert("success", t(response?.message), 6000);
		}
		setClearCataloguePopup(false);
	}

	return (
		<AccessControl
			requiredPermissions={[
				{
					permission: "product catalogue",
					read: true,
				},
			]}
			renderNoAccess={true}
		>
			<div className="row">
				<div className="col-md-11 ms-auto px-4">
					<WindowHeightComponent>
						<div className="d-flex flex-column overflow-auto h-100">
							<div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
								{t("Manage product catalogue")}
							</div>
							<div className="flex-1 d-flex flex-column overflow-auto h-100">
								{bpId ?
									<div className="d-flex justify-content-between mb-2">
										<div className="user-tabs my-3">
											<Button
												className={`${filterobj.status == "active" ? "active" : ""
													} border-0 bg-transparent tab-btn me-4`}
												handleClick={() => handleTabClick("active")}
												title={t("Active")}
											/>
											<Button
												className={`${filterobj.status === "inactive" ? "active" : ""
													} border-0 bg-transparent tab-btn me-4`}
												handleClick={() => handleTabClick("inactive")}
												title={t("Inactive")}
											/>
											<Button
												className={`${filterobj.status === "rejected" ? "active" : ""
													} border-0 bg-transparent tab-btn me-4`}
												handleClick={() => handleTabClick("rejected")}
												title={t("Rejected")}
											/>
											<Button
												className={`${filterobj.status === "pending" ? "active" : ""
													} border-0 bg-transparent tab-btn me-4`}
												handleClick={() => handleTabClick("pending")}
												title={t("Pending")}
											/>
										</div>
										<AccessControl
											requiredPermissions={[
												{
													permission: "product catalogue",
													create: true,
												},
											]}
										>
											<div className="d-flex">
												<div className="text-center">
													<InputWithButton
														handleClick={handleSearch}
														noerror={true}
														placeholder={t("Search by sku number")}
														buttonClass="height45"
													/>
												</div>
												<div className="d-flex ms-3">
													<Button
														title={t("Filter")}
														className="submit-btn height45"
														handleClick={() =>
															setState((prev) => ({ ...prev, filterPopup: true }))
														}
													/>
													<div className="ms-3">
														<div className={`d-flex justify-content-center`}>
															<label
																className={`${styles.file_input} d-flex align-items-center  justify-content-center reject-btn  cursor-pointer mb-1`}
																htmlFor="customFile"
															>
																<div>
																	<Upload />
																	<span className="ps-3">{t("Import")}</span>
																	<input
																		className={`visually-hidden `}
																		type="file"
																		onClick={(e: any) => e.target.value = null}
																		accept="xls/*"
																		id="customFile"
																		onChange={handleImport}
																		ref={fileInputRef}
																	/>
																</div>
															</label>
														</div>
														<p
															className={`${styles.import_error_text} mb-0 text-gray text-break text-truncate`}
															title={t("Please select excel file upto 10mb")}
														>
															{t("Please select excel file upto 10mb")}
														</p>
													</div>
													<div className="ms-3">
														<IconWithLabel
															hoverText={t("Download template")}
															svgData={IconData.DownloadIcon}
															handleClick={handleExportTemplate}
															classname1="hoversvg"
														/>
													</div>
													<div className="ms-3">
														<Button
															title={t("Clear catalogue")}
															className="submit-btn height45"
															handleClick={() => setClearCataloguePopup(true)}
														/>
													</div>
												</div>
											{catalogue?.some((item: any) => item.checked) &&
												<div className="ms-3">
												<Button
													title={t("Accept selected catalogue")}
													className="submit-btn height45"
													handleClick={handleAcceptSelectedCatalogue}
												/>
											</div>}
											</div>
										</AccessControl>
									</div> :
									<AccessControl
										requiredPermissions={[
											{
												permission: "product catalogue",
												create: true,
											},
										]}
									>
										<div className="text-end">
											<InputTextfield
												placeholder={t('Search by name')}
												value={stateobj.searchtext}
												handleChange={(e) => setStateObj((prevState: any) => ({
													...prevState,
													searchtext: e.target.value
												}))}
												className={`rounded`}
											/>
										</div>
									</AccessControl>
								}
								<div className="form-group mt-2 flex-1 overflow-auto d-flex flex-column h-100 overflow-auto">
									{bpId ?
										<>
											<div className="flex-1 overflow-auto">
												<TableStructure
													data={catalogue}
													actionClass="w-10 text-center"
													headers={state.filteredTableHeaders}
													values={state.filteredTableValues}
													isAction={true}
													handleEdit={filterobj.status === 'inactive' || filterobj.status === 'pending' ? undefined : handleEdit}
													handleArchive={filterobj.status === 'rejected' || filterobj.status === 'pending' ? undefined : handleArchivePopup}
													handleAccept={filterobj.status === 'pending' ? handleAccepttPopup : undefined}
													handleCheckBox={filterobj.status === 'pending' ? handleCheckBox : undefined}
													selectAllChecked={selectAllCheckbox}
												/>
											</div>
										</>
										:
										<div className="row mx-0  mb-3">
											<div className={`border h-100 overflow-auto p-4 rounded shadow ${styles.mediaboxwithoutmodal}`}>
												<div className="d-flex flex-wrap">
													{filteredBrandPartners?.length ? filteredBrandPartners?.map((brandpartner: any) => (
														<div key={brandpartner.brand_partner_id} className={`text-center ${styles?.media_folder_box_modal}`}>
															<div
																className={`${styles.media_folder_box_modal_inner} py-2 rounded d-flex
																justify-content-center align-items-center flex-column
																position-relative shadow w-100 h-100`}
															>
																<div
																	id={brandpartner.brand_partner_id}
																	className={`${styles.mediafoldericon} cursor-pointer`}
																	onClick={() => handleBrandClick(brandpartner)}
																>
																	<FolderIcon />
																</div>

																<div
																	className={`text-break mt-2 w-100 ${styles.edit_folder}
																	d-flex justify-content-center flex-column px-1`}
																>
																	<div
																		className={`text-break px-1 ${styles.medialibraryfoldername}`}
																		id={brandpartner.brand_partner_id}
																	>
																		{brandpartner.title}
																	</div>
																</div>
															</div>
														</div>
													)) :
														<div className="col-12 text-center text-danger">
															{t(CommonConstants.NO_RESULTS_FOUND)}
														</div>
													}
												</div>
											</div>
										</div>
									}
								</div>
								<div className="row m-0 position-relative mt-2">
									{stateobj.count > filterobj.limit && stateobj.totalpages > 1 && bpId != 0 && (
										<Pagination
											currentPage={stateobj.currentpage}
											totalPages={stateobj.totalpages}
											handlePaginationClick={handlePaginationClick}
										/>
									)}
									<div className="col-md-6 float-start p-0">
										<Button
											className="back-btn shadow-none float-start text-decoration-none"
											handleClick={handleBack}
											title={t(CommonConstants.BACK)}
										/>
									</div>
								</div>
							</div>
						</div>

						{state.showPopup && (
							<Popup
								title={t("Upload excel file")}
								body={t(`Please upload excel file upto 10mb`)}
								cancel={() =>
									setState((prev) => ({ ...prev, showPopup: !state.showPopup }))
								}
								submit={() =>
									setState((prev) => ({ ...prev, showPopup: !state.showPopup }))
								}
								yestext={t("Okay")}
							/>
						)}
						{state.filterPopup && (
							<Popup
								title={t("Select the columns to display in product catalogue")}
								body={
									<ShipmentReportsFilterBody
										setSelectedColumns={setSelectedColumns}
										selectedColumns={selectedColumns}
										filterValues={filterValues}
									/>
								}
								bodyclassName="text-revert"
								notext={t("Close")}
								cancel={() =>
									setState((prev) => ({
										...prev,
										filterPopup: !state.filterPopup,
									}))
								}
							/>
						)}
						{popup && (
							<Popup
								title={t("Confirmation")}
								body={t(`Are you sure you want to ${filterobj.status == "active" ? "archive" : "activate"
									} this?`)}
								yestext={t("Yes")}
								notext={t("No")}
								cancel={() => setPopup(false)}
								submit={handleArchive}
							/>
						)}
						{acceptPopup.popUp && (
							<Popup
								title={t("Confirmation")}
								body={t(`Are you sure you want to accept selected catalogue/s?`)}
								yestext={t("Yes")}
								notext={t("No")}
								cancel={() => setAcceptPopup((prev) => ({ ...prev, popUp: false, catalogue_id: null }))}
								submit={() => acceptCatalogue(acceptPopup.catalogue_id)}
							/>
						)}
						{state.importedrejecteditems.view && (
							<PublishListPopup
								title="Rejected item list"
								rejected={state.importedrejecteditems.rejected}
								handleSubmit={handlePopupSubmit}
							/>
						)}
						{clearCataloguePopup && (
							<Popup
								title={t("Confirmation")}
								body={t("Are you sure you want to clear catalogue data? This action cannot be undone.")}
								yestext={t("Yes")}
								notext={t("No")}
								cancel={() => setClearCataloguePopup(false)}
								submit={clearCatalogueData}
							/>
						)}
					</WindowHeightComponent>
				</div>
			</div>
		</AccessControl>
	);
};

export default ProductCatalogue;
