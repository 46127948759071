import React, { useState } from 'react';
import DivWithoutPopup from '../DivWithoutPopup';
import { t } from '../../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import Popup from '../../../utils/popup';
import SelectTierPopup from '../TierPopup/SelectTierPopup';

interface QaFinishMoleculeProps {
    handleClick: (status: boolean, tier?: any, tierTitle?: any) => void;
    selectedTier: string;
    tierlist?: any;
}

const QaFinishMolecule: React.FC<QaFinishMoleculeProps> = ({ handleClick, selectedTier, tierlist = [] }) => {
    const [showpopup, setShowPopup] = useState<boolean>(false)

    const onSubmit = (status: boolean) => {
        if (status) {
            if (selectedTier != "") {
                handleClick && handleClick(true, selectedTier);
            } else {
                setShowPopup(true)
            }
        } else {
            handleClick && handleClick(false, selectedTier);
        }
    }

    const handleSelect = (tier: string, tierTitle: string) => {
        if (tier && handleClick) {
            setShowPopup(!showpopup)
            handleClick(true, tier, tierTitle);
        }
    }

    return (
        <div className="">
            {<DivWithoutPopup
                body={t("Confirm the item is properly finished") + "?"}
                yestext={t(CommonConstants.YES)}
                notext={t(CommonConstants.NO)}
                submit={() => onSubmit(true)}
                cancel={() => onSubmit(false)}
            />}
            {(showpopup && selectedTier == "") &&
                <Popup
                    title={t("Select Tier")}
                    body={<SelectTierPopup
                        handleClick={handleSelect}
                        tierlist={tierlist}
                    />}
                    cancel={() => setShowPopup(!showpopup)}
                />
            }

        </div>
    )
}
export default QaFinishMolecule;
