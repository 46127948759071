import React from 'react';
import { t } from '../../pages/Translations/TranslationUtils';
import ImageField from './ImageField';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Mousewheel, Pagination, Keyboard, Navigation } from 'swiper/modules';
interface ImageCarouselProps {
    images: any[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
    if (!images || images.length === 0) {
        return <ImageField
            value={process.env.PUBLIC_URL +
                "/images/" +
                "ProductFront.png"
            }
            className=""
        />
    }

    return (
        <Swiper
            pagination={{
                type: 'fraction',
            }}
            navigation={true}
            loop={true}
            mousewheel={true}
            keyboard={{
                enabled: true,
            }}
            modules={[Mousewheel, Pagination, Keyboard, Navigation]}
            className="mySwiper"
        >
            {images?.map((image, index) => (
                <SwiperSlide key={image.id || image.url || index}>
                    <ImageField value={image} altText={`${t('Image')} ${index}`} className='' />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ImageCarousel;
