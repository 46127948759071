import React, { useState, useEffect } from 'react';
import Button from '../atoms/Button';
import { t } from '../../pages/Translations/TranslationUtils';
import {
  sortCategories,
  SortingConstants,
  CommonConstants
} from '../../pages/Shipment/Constants/ShipmentConstants';
import Layout from '../../pages/Shipment/Layout';
import customAlert from '../atoms/CustomAlert';
import { APICALL } from '../../services/ApiServices';
import { createSortUrl, fetchSortUrl } from '../../routes/ApiEndPoints';
import { useNavigate } from "react-router-dom";
import SuccessBoxMolecule from '../molecules/SuccessBoxMolecule';
import SortCategoriesMolecule from '../molecules/SortCategoriesMolecule';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import { WorkflowStageNames } from '../../utils/constants/WorkflowStageNames';
import ScreenDisable from '../../utils/ScreenDisable';
import { useUserContext } from '../../routes/Contextlib';
import useUpdateInactivityTimeoutService from '../../services/useUpdateInactivityTimeoutService';
import { useInactivityTimeoutContext } from '../../services/useInactivityTimeoutContext';

interface State {
  isSubmitted: boolean;
  apiData: Array<{
    sort_category_id: number;
    name: string;
    title: string;
    counts: number;
  }>;
  timerrunning: boolean;
  presentFlowCount: number;
}

const CreateSortOrganism: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    isSubmitted: false,
    apiData: [],
    timerrunning: true,
    presentFlowCount: 0
  });
  const localdata = CommonShipmentServices.getPostData();
  const { user } = useUserContext();

  const { updateTimeout } = useUpdateInactivityTimeoutService();
  const { inactivityTimeoutState } = useInactivityTimeoutContext();
  const [trackTime, setTrackTime] = useState<boolean>(false)
  useEffect(() => {
    setTrackTime(localStorage.getItem("trackTime") && localStorage.getItem("trackTime") == "yes" ? true : false)
  }, [localStorage.getItem("trackTime")]);

  useEffect(() => {
    if (trackTime) {
      updateTimeout("sort")
    }
  }, [trackTime]);

  const handleTimeoutNavigation = async () => {
    await timeTracker(false, Number(inactivityTimeoutState.redirectTime));
    navigate(inactivityTimeoutState.redirectUrl);
  };

  useEffect(() => {
    if (inactivityTimeoutState.timeoutRedirection) {
      handleTimeoutNavigation()
    }
  }, [inactivityTimeoutState.timeoutRedirection]);

  const bpDetails: any = JSON.parse(localStorage?.getItem('bp_details') ?? '{}');
  let ignorecounts = ["recycle", 'out_of_scope'];
  let recycleCounts = ["recycle"];

  //Function to fetch sort data
  const fetchSortingData = async () => {
    try {
      const postdata = {
        shipment_id: localdata?.shipment_id,
        bpname: bpDetails?.title
      }
      const response: any = await APICALL.service(fetchSortUrl + `/${bpDetails?.brand_partner_id}`, 'POST', postdata);
      if (response?.status === 200) {
        let data = response?.data?.map((item: any) => {
          item.status = ignorecounts.includes(item?.name) ? false : true;
          item.tier = item.name === "recycle" ? process.env.REACT_APP_RECYCLE_TIER_ID : null;
          return item;
        });
        // Update the state with the count
        setState(prevState => ({ ...prevState, apiData: data, presentFlowCount: response.presentFlowCount }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (user != null) {
      // fetching the data from database
      fetchSortingData();
      if (trackTime) {
        timeTracker(true);

        window.addEventListener('beforeunload', () => timeTracker());
        return () => {
          timeTracker();
          window.removeEventListener('beforeunload', () => timeTracker());
        }
      };
    };
  }, [user != null, trackTime]);

  const timeTracker = async (reloaded: boolean = false, timeoutRedirection: number | null = null) => {
    let time = await CommonShipmentServices.saveTimerApi(
      'bp',
      localdata?.brand_partner_id,
      user?.user_id,
      WorkflowStageNames.SORT,
      reloaded,
      false,
      timeoutRedirection
    );
  }

  //To handle counts change
  const handleCountChange = (sortCategoryId: number, newCount: number) => {
    // Assuming state.apiData is part of the component state
    setState(prevState => ({
      ...prevState,
      apiData: prevState.apiData?.map(item => {
        if (item.sort_category_id === sortCategoryId) {
          // Update the counts for the specific sortCategoryId
          return {
            ...item,
            counts: newCount,
          };
        }
        return item;
      }),
    }));
  };


  //To handle counts validations
  const validateCounts = () => {
    let count = state.apiData.reduce((acc, item) => acc + item.counts, 0)
    if (count <= 0) {
      return t("Please add items");
    } else if (count > state.presentFlowCount) {
      return `${t(SortingConstants.ERROR_MESSAGE1)}: ${state.presentFlowCount}`
    }
    return false;
  };

  //To handle submit
  const handleSubmit = async () => {
    const validate: any = validateCounts();
    if (!validate) {
      try {
        const postdata = {
          recycle_merch_rack_id: Number(process.env.REACT_APP_RECYCLE_MERCHRACK_ID),
          sortdata: [...state.apiData],
          totalcounts: state.apiData?.reduce((acc, item) => acc + item.counts, 0),
          notmovablecounts: state.apiData?.reduce((acc, item) => !ignorecounts.includes(item.name) ? acc : acc + item.counts, 0),
          moveToRecycleCounts: state.apiData?.reduce((acc, item) => !recycleCounts.includes(item.name) ? acc : acc + item.counts, 0),
          ...localdata,
        }
        const response: any = await APICALL.service(createSortUrl, 'POST', postdata);
        if (response?.status !== 200) {
          throw new Error("Request failed");
        }
        setState(prevState => ({ ...prevState, isSubmitted: true }));
      } catch (error) {
        console.log(error);
      }
    } else {
      customAlert("error", validate, 6000);
    }
  }

  const goBackToPreviousPage = () => {
    // Implement your logic to navigate back to the previous page
    navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
  };

  const setTimerRunning = (timer: boolean) => {
    setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
  }

  return (
    <Layout
      pagename={('Sorting')}
      logo={true}
      timer={true}
      stage={WorkflowStageNames.SORT}
      ongoing={!state.isSubmitted && trackTime}
      // totaltime={state.totaltime}
      setTimerRunning={setTimerRunning}
    >
      <div className='position-relative flex-1 overflow-auto'>
        <ScreenDisable display='none' />
        {/* Sorting sortCategories */}
        {state.isSubmitted ? (
          <SuccessBoxMolecule
            apiData={state.apiData}
            SortingConstants={SortingConstants}
          />
        ) : (
          <SortCategoriesMolecule
            sortCategories={sortCategories}
            apiData={state.apiData}
            handleCountChange={handleCountChange}
            timer={state.timerrunning}
          />
        )}
      </div>
      {/* Buttons */}
      <div className={`d-flex ${state.isSubmitted ? 'justify-content-end' : 'justify-content-between '} mt-2`}>
        {(!state.isSubmitted) && <Button
          title={t(CommonConstants.BACK)}
          handleClick={goBackToPreviousPage}
          className="back-btn shadow-none"
        />}
        <Button
          title={state.isSubmitted ? t(CommonConstants.NEXT) : t(CommonConstants.SAVE)}
          handleClick={state.isSubmitted ? goBackToPreviousPage : handleSubmit}
          className='submit-btn shadow-none'
        />
      </div>
    </Layout>
  );
};

export default CreateSortOrganism;
