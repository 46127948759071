export const statevalue = {
    renewal_id: "",
    sku: "",
    serial_number: "",
    style_number: "",
    sku_number: '',
    raven_generated_sku: true,
    color: null,
    colorlabel: '',
    size: null,
    sizelabel: '',
    dimension: null,
    product_type: null,
    product_type_title: "",
    product_tier: null,
    product_group: null,
    product_group_title: "",
    gender: null,
    clean_process: null,
    weight: "",
    merchandise_image: [],
    merchandise_image_url: [],
    merch_rack: "",
    old_merch_rack: "",
    merchandise_status: true,
    fibre_content: null,
    product_description: "",
    createdAt: null,
    updatedAt: null
}

export const getrowvalues = (configdata: any, dataentry: boolean = false, merchandisingDisabledFields: boolean = false) => {
    let rowvalues = [
        {
            0: {
                'name': 'style_number',
                'type': 'text',
                'required': true,
                'validation': 'required_text',
                'value': '',
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('style_number') : false
            },
            1: {
                'name': dataentry ? 'brand_partner' : 'product_tier',
                'type': dataentry ? 'text' : 'buttonwithpopup',
                'value': null,
                'validation': dataentry ? '' : 'select',
                'options': dataentry ? null : configdata.bpdata.product_tier,
                disable: true
            }
        },
        {
            0: {
                'name': 'color',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.color,
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('color_id') : false
            },
            1: {
                'name': 'weight',
                'type': 'text',
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('weight') : false
            }
        },
        {
            0: {
                'name': 'size',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.size,
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('size_id') : false
            },
            1: {
                'name': 'clean_process',
                'type': 'select',
                'value': null,
                'options': configdata.bpdata.clean_process
            }
        },
        {
            0: {
                'name': 'gender',
                'type': 'select',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.gender,
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('gender_id') : false
            },
            1: {
                'name': 'dimension',
                'type': dataentry ? 'select' : 'buttonwithpopup',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.dimension
            }
        },
        {
            0: {
                'name': 'product_group',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.product_group,
                disable: dataentry || merchandisingDisabledFields? configdata.disabledfields.includes('product_group_id') : false
            },
            1: {
                'name': 'fibre_content',
                'type': 'select',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.fibre_content,
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('fibre_content_id') : true
            }
        },
        {
            0: {
                'name': 'product_type',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.product_type,
                disable: dataentry || merchandisingDisabledFields ? configdata.disabledfields.includes('product_type_id') : false
            },
            1: {
                'name': dataentry ? 'shipment' : 'product_description',
                'type': 'text',
                'disable': true
            }
        }
    ]
    return rowvalues;
}