import React from 'react'
import ColorMolecule from '../molecules/ColorMolecule';

interface Props {
    item: any;
    type: string;
    handleClick: () => void;
}

const ProductCatalogueCard: React.FC<Props> = ({
    item,
    type,
    handleClick
}) => {
    return (
        <div className={`${type === 'sku' ? 'row' : 'flex-column'} d-flex cursor-pointer m-0 h-100`} onClick={handleClick}>
            {type === 'sku' && <div className="col-4 m-auto" title={'product-image'}>
                <img width={80} src={`${item?.image_url ?? '../images/ProductFront.png'}`} alt={'product-image'} className={'img-fluid'} />
            </div>}
            <div className={`${type === 'sku' ? 'col-4 border-start border-end border-1 border-dark p-0' : 'flex-1'}`}>
                <ColorMolecule
                    color={{ color_code: item?.hex_color_code, title: item?.color_name, squareview: true}}
                    outerStyle={{width: '100%', height: type === 'sku' ? '100%' : '12vh', marginRight: '8px'}}
                    innerStyle={{ height: "100%", width: "100%", maxWidth: "100%" }}
                    select
                />
            </div>
            <div className={`${type === 'sku' ? 'col-4 m-auto' : 'flex-1 py-2 size-bg-catalogue'} text-break`}>
                {item?.hex_color_code && <div className='popupFont'>
                    {item?.color_name}</div>}
                {type !== 'color' && <> 
                    {<div className='text-break popupFont'>{item?.size}</div>}
                    <div className='text-break popupFont'>{item?.sku_number}</div>
                </>}
            </div>
        </div>
    )
}

export default ProductCatalogueCard;
