import React from "react";
import Button from "../atoms/Button";
import Calendar_fill from "../../static/icons/Calendar_fill";
import DatePicker from "react-datepicker";
import MultiSelect from "../atoms/MultiSelectField";
import IconWithLabel from "../atoms/IconWithLabel";
import IconData from "../../static/IconData";
import { t } from "../../pages/Translations/TranslationUtils";
import { useUserContext } from "../../routes/Contextlib";
import InputTextfield from "../atoms/InputTextField";

interface CustomFilterComponentProps {
  filters: any;
  handleSelectedFilter: (value: any, key: string) => void;
  applyFilter?: () => void;
  handleDownload?: () => void;
  handleFilter?: () => void;
  handleSearch?: () => void;
  clearFilter?: () => void;
}

const CustomFilterHeaderForReports: React.FC<CustomFilterComponentProps> = ({
  filters,
  handleSelectedFilter,
  applyFilter,
  handleFilter,
  handleDownload,
  clearFilter
}) => {  
  
  const { user } = useUserContext();
  return (
    <div className="d-flex mb-3 justify-content-xl-between justify-content-end flex-lg-wrap flex-xl-nowrap flex-wrap justify-content-lg-end">
      <div className="d-flex gap-2 flex-wrap multi-select-reports-header mb-1">
        {filters.map((filter: any) => {
          switch (filter?.type) {
            case "multiSelect":
              return (
                <div key={filter?.key}>
                  <MultiSelect
                    key={filter?.key}
                    placeholder={filter?.label}
                    name={filter?.key}
                    isMulti={true}
                    options={filter?.options}
                    handleChange={(e) => handleSelectedFilter(e, filter?.key)}
                    isSearchable
                    standards={filter?.selected ? filter?.selected : null}
                    disabled={filter?.disable}
                  />
                </div>)
            
            case "calender":
              return (
                <div className="report-date-container" key={filter?.key}>
                  <DatePicker
                    autoComplete="off"
                    name={filter?.key}
                    dateFormat={user?.date_format}
                    placeholderText={filter?.label}
                    selected={filter?.selected}
                    showMonthDropdown
                    showYearDropdown
                    className="w-100"
                    calendarStartDay={1}
                    dropdownMode="select"
                    isClearable={!filter?.disable}
                    minDate={filter?.min_date}
                    maxDate={filter?.max_date}
                    onChange={(date: any) =>
                      handleSelectedFilter(date, filter?.key)
                    }
                    onKeyDown={(e: any) => {
                      if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }
                    }}
                    disabled={filter?.disable}
                  />
                  {!filter?.selected ? (
                    <span className="cal-open-button">
                      <Calendar_fill />
                    </span>
                  ) : null}
                </div>
              );

            case "text":
                return (
                  <div key={filter?.key} className="w-200px">
                    <InputTextfield
                      key={filter?.key}
                      className="border rounded text-gray w-100 px-3 form-control"
                      placeholder={filter?.label}
                      name={filter?.key}
                      value={filter?.selected}
                      handleChange={(e) => handleSelectedFilter(e.target.value, filter?.key)}
                      isDisabled={filter?.disable}
                      note={t("Enter comma-separated values to search multiple entries (ABCD1234, XYZ78901).")}
                    />
                  </div>
                );

            default:
              return null;
          }
        })}
      </div>
      <div className="d-flex gap-4 ms-2">
        {clearFilter && (
          <IconWithLabel
            hoverText={t("Clear all")}
            svgData={IconData.ClearFilter}
            handleClick={clearFilter}
            classname1="hoversvg"
          />
        )}
        {applyFilter && (
          <Button
            title={t("Apply")}
            hoverText={t("Apply filters")}
            className="submit-btn"
            handleClick={applyFilter}
          />
        )}
        {handleDownload && (
          <IconWithLabel
            hoverText={t("Download")}
            svgData={IconData.DownloadIcon}
            handleClick={handleDownload}
            classname1="hoversvg"
          />
        )}
        {handleFilter && (
          <Button
            title={t("Filter")}
            className="submit-btn"
            handleClick={handleFilter}
          />
        )}
      </div>
    </div>
  );
};

export default CustomFilterHeaderForReports;