import React, { useEffect, useState } from "react";
import TableStructure from "../atoms/TableStructure";
import { ShipmentReportsConstant } from "../../pages/Shipment/Constants/ReportConstants";
import { APICALL } from "../../services/ApiServices";
import {
  fetchBpwiseList,
  fetchList,
  getShipmentReports,
} from "../../routes/ApiEndPoints";
import DownloadXlsx from "../../services/DownloadXlsx";
import Button from "../atoms/Button";
import Popup from "../../utils/popup";
import ShipmentReportsFilterBody from "../molecules/ShipmentReportsFilterBody";
import MultiSelect from "../atoms/MultiSelectField";
import DatePicker, { registerLocale } from "react-datepicker";
import IconWithLabel from "../atoms/IconWithLabel";
import IconData from "../../static/IconData";
import Calendar_fill from "../../static/icons/Calendar_fill";
import {
  CommonConstants,
  ConfigConstants,
} from "../../pages/Shipment/Constants/ShipmentConstants";
import Pagination from "../../services/Paginations";
import { t } from "../../pages/Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../routes/Contextlib";
import CommonServices from "../../services/CommonServices";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import customAlert from "../atoms/CustomAlert";
import FormValidation from "../../services/FormValidation";
import BarcodeBody from "./BarcodeBody";

interface configData {
  BrandPartner: any;
  WarehouseLocation: any;
  FilteredWarehouseLocation: any;
  ProductGroup: any;
  FilteredProductGroup: any;
}

interface FilterData {
  brand_partner_id: { value: any; label: any }[];
  product_group: any[];
  location: any[];
  publish_status: any[];
  dateof_delivery: Date | null;
  from_date: Date | null;
  to_date: Date | null;
  offset: number;
  limit: number;
  totalLength: number;
  totalPages: number;
}

type barcodeData = {
  title: string;
  barcodeId: string;
  barcodeUrl: string;
};

const ShipmentReportsOrganism = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [viewBarcode, setViewBarcode] = useState(false);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [barcoodeData, setBarcoodeData] = useState<barcodeData>({
    title: "",
    barcodeId: "",
    barcodeUrl: "",
  });
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [configDetails, setConfigDetails] = useState<configData>({
    BrandPartner: [],
    WarehouseLocation: [],
    FilteredWarehouseLocation: [],
    ProductGroup: [],
    FilteredProductGroup: [],
  });
  const [filterPopup, setFilterPopup] = useState(false);
  const [apply, setApply] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState<FilterData>({
    brand_partner_id: [],
    product_group: [],
    location: [],
    publish_status: [],
    dateof_delivery: null,
    from_date: null,
    to_date: null,
    offset: 0,
    limit: 10,
    totalLength: 0,
    totalPages: 1,
  });

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "cartons",
    "location",
    "items",
    "weight",
    "product_group",
    "pallets",
    "publish_status",
    "dateof_delivery",
    "createdAt",
  ]);
  const publish_status_options = [
    { value: 0, label: "Inactive" },
    { value: 1, label: "In progress" },
    { value: 2, label: "Published" },
    { value: 3, label: "Ready" },
  ];
  const [filteredTableHeaders, setFilteredTableHeaders] = useState(
    ShipmentReportsConstant.TABLE_HEADERS
  );
  const [filteredTableValues, setFilteredTableValues] = useState(
    ShipmentReportsConstant.TABLE_VALUES
  );
  const filterValues = [
    { value: 1, title: t("Cartons"), handle: "cartons" },
    { value: 2, title: t("Location"), handle: "location" },
    { value: 3, title: t("Items"), handle: "items" },
    { value: 4, title: t("Weight"), handle: "weight" },
    { value: 5, title: t("Product group"), handle: "product_group" },
    { value: 6, title: t("Pallets"), handle: "pallets" },
    { value: 7, title: t("Status"), handle: "publish_status" },
    { value: 8, title: t("Date of delivery"), handle: "dateof_delivery" },
    { value: 9, title: t("Created on"), handle: "createdAt" },
  ];
  const savedPrinter = localStorage.getItem("savedPrinter") || ""; 

  const handlePaginationClick = (pageNumber: number) => {
    const newOffset = (pageNumber - 1) * selectedFilterData.limit;
    setSelectedFilterData((prevData) => ({
      ...prevData,
      offset: newOffset,
    }));
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    setApply(false);
  }, [
    selectedFilterData.brand_partner_id,
    selectedFilterData.product_group,
    selectedFilterData.location,
    selectedFilterData.dateof_delivery,
    selectedFilterData.from_date,
    selectedFilterData.to_date,
  ]);

  useEffect(() => {
    if (Object.keys(configDetails).length > 0) {
      fetchShipmentReports();
    }
  }, [selectedFilterData.offset]);

  async function fetchShipmentReports(
    downloadData: any = null,
    filterClick: boolean = false,
    initialconfigdata?: any
  ) {
    try {
      let filters =
        downloadData != null ? { ...downloadData } : { ...selectedFilterData };
      const dateOfDelivery: any = await CommonServices.getTimezoneOffset(
        selectedFilterData?.dateof_delivery
      );
      filters.dateof_delivery = dateOfDelivery;
      const fromDate: any = await CommonServices.getTimezoneOffset(
        selectedFilterData?.from_date ?? null
      );
      filters.from_date = fromDate;
      const toDate: any = await CommonServices.getTimezoneOffset(
        selectedFilterData?.to_date ?? null
      );
      filters.to_date = toDate ?? null;

      if (filterClick) {
        filters.offset = 0;
        if (
          selectedFilterData.brand_partner_id.length > 0 ||
          selectedFilterData.product_group.length > 0 ||
          selectedFilterData.location.length > 0 ||
          selectedFilterData.dateof_delivery != null ||
          selectedFilterData.from_date != null ||
          selectedFilterData.to_date != null
        ) {
          setApply(true);
        }
      }

      if (apply == false && downloadData == null && filterClick == false) {
        filters.brand_partner_id = [];
        filters.product_group = [];
        filters.location = [];
        filters.from_date = null;
        filters.to_date = null;
        filters.dateof_delivery = null;
      }

      const response = await APICALL.service(
        getShipmentReports,
        "POST",
        filters
      );

      if (response.status === 200) {
        const totalPages = Math.ceil(
          response.totalLength / selectedFilterData.limit
        );
        if (filterClick) {
          setCurrentPage(1);
          setSelectedFilterData((prevData) => ({
            ...prevData,
            offset: 0,
          }));
        }

        setSelectedFilterData((prev) => ({
          ...prev,
          totalLength: response.totalLength,
          totalPages: totalPages,
        }));

        const data = response.data;
        const updatedData = data?.map((item: any) => {
          (item.publish_status = CommonServices?.capitalizeLabel(item.publish_status ?? ""));
          (item.location = getConfigDataValues(
            item.location,
            initialconfigdata?.WarehouseLocation?.length ? initialconfigdata?.WarehouseLocation : configDetails.WarehouseLocation,
            "warehouse_location_id"
          ));
            (item.original_dateof_delivery = item.dateof_delivery);
              (item.dateof_delivery = CommonServices.showDateBasedOnFormat(
                item.dateof_delivery,
                user?.date_format ?? "dd-MM-yyyy"
              ));
          (item.createdAt = CommonServices.showDateBasedOnFormat(
            item.createdAt,
            user?.date_format ?? "dd-MM-yyyy"
          ));
          (item.shipment_id =
            `${item.barcode_id}: ${item.title}`
          );
          return item;
        });
        if (downloadData != null) {
          return updatedData;
        }
        setShipmentDetails(updatedData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getConfigDataValues = (id: any, data: any, key: string) => {
    if (data.length > 0) {
      const title = data?.filter((item: any) => item[key] == id)[0]?.title;
      return title;
    }
  };

  async function fetchConfigData() {
    try {
      const postData = {
        method: "POST",
        data: {
          list: [
            ConfigConstants.ALL_WAREHOUSELOCATION,
            ConfigConstants.ALLPRODUCTGROUP,
            ConfigConstants.BRANDPARTNER,
          ],
        },
      };
      const response = await APICALL.service(fetchList, "POST", postData, true);
      if (response.status === 200) {
        let data: any = {
          WarehouseLocation: CommonServices.sortAlphabattically(response.data?.WarehouseLocation),
          ProductGroup: CommonServices.sortAlphabattically(response.data?.ProductGroup),
          BrandPartner: CommonServices.sortAlphabattically(response.data?.BrandPartner)
        }
        fetchShipmentReports(null, false, data);
        setConfigDetails(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (selectedFilterData.brand_partner_id.length > 0) {
      fetchBpwiseConfigData();
    } else {
      setSelectedFilterData((prevData) => ({
        ...prevData,
        location: [],
        product_group: [],
      }));
    }
  }, [selectedFilterData.brand_partner_id]);

  async function fetchBpwiseConfigData() {
    const bpIds: any[] = selectedFilterData.brand_partner_id.map(
      (item: any) => {
        return item.value;
      }
    );
    const finalBpIds: string[] = Array.isArray(bpIds) ? bpIds : [bpIds];

    try {
      const postData = {
        method: "POST",
        data: {
          list: [
            ConfigConstants.ACTIVE_BPWISE_PRODUCT_GROUP,
            ConfigConstants.BPWISE_WAREHOUSE_LOCATION,
          ],
          bp_id: finalBpIds,
        },
      };
      const response = await APICALL.service(
        fetchBpwiseList,
        "POST",
        postData,
        true
      );
      if (response.status === 200) {
        const configData = { ...configDetails };
        configData.FilteredProductGroup = response.data.cd_product_group;
        configData.FilteredWarehouseLocation =
          response.data.cd_warehouse_location;

        let product_group_filterValue: any;
        let location_filterValue: any;
        if (
          configData.FilteredProductGroup.length === 1 &&
          selectedFilterData.brand_partner_id.length === 1
        ) {
          product_group_filterValue = [
            {
              value: configData.FilteredProductGroup[0].product_group_id,
              label: configData.FilteredProductGroup[0].title,
            },
          ];
        } else {
          product_group_filterValue = [];
        }
        if (
          configData.FilteredWarehouseLocation.length === 1 &&
          selectedFilterData.brand_partner_id.length === 1
        ) {
          location_filterValue = [
            {
              value:
                configData.FilteredWarehouseLocation[0].warehouse_location_id,
              label: configData.FilteredWarehouseLocation[0].title,
            },
          ];
        } else {
          location_filterValue = [];
        }
        setSelectedFilterData((prevData) => ({
          ...prevData,
          product_group: product_group_filterValue,
          location: location_filterValue,
        }));
        setConfigDetails(configData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getSelectOptions = (data: any, id: any, label: any) => {
    const convertedData: any = Object.values(data)?.map((item: any) => ({
      value: item[id],
      label: item[label],
    }));
    const sortedData = convertedData.sort((a: any, b: any) => {
      const labelA = a.label?.toLowerCase().trim() || '';
      const labelB = b.label?.toLowerCase() || '';
      return labelA.localeCompare(labelB);
    });
    return sortedData;
  };

  const handleDownload = async (data: any = null) => {
    if (data == null) {
      const filters = { ...selectedFilterData };
      filters.limit = filters.totalLength;
      filters.offset = 0;
      data = await fetchShipmentReports(filters);
    }

    if (data != null) {
      const selectedFields = data?.map((obj: any) =>
        selectedColumns.reduce(
          (acc: any, column: string) => {
            acc[column] = obj[column];
            return acc;
          },
          { Id: obj.shipment_id }
        )
      );

      //converting product group array to string
      if (selectedColumns.includes("product_group")) {
        selectedFields?.map((item: any) => {
          item.product_group = item.product_group
            ?.map((obj: any) => obj.label)
            .join(", ");
        });
      }
      DownloadXlsx(selectedFields, "Shipment_reports");
    }
  };

  const handlePrint = async (data: any) => {
    try {
      let shipment: any = Object.values(shipmentDetails)?.filter((item: any) => item.barcode_id == barcoodeData.barcodeId)?.[0];

      const postdata = {
        "printerName": data?.inputValue.trim(),
        "labelType": "LPN",
        "labelData": {
          "licensePlateNumber": barcoodeData.barcodeId,
          "clientId": shipment?.wms_bp_id,
          "shipmentDate": CommonServices.showDateBasedOnFormat(shipment?.original_dateof_delivery, 'dd/MM/yyyy'),
          "palletCount": shipment?.pallets,
          "cartonCount": shipment?.cartons,
          "printCount": data.printCount,
          "totalPrintCount": data.totalPrintCount
        }
      }

      let error: any = validatepostdata(postdata);
      if (!error) {
        let res: any = await CommonServices.printLabelApi(postdata);
        res?.message && customAlert(res?.type, t(res?.message), 6000);
        if (res.statusCode === 201) {
          localStorage.setItem("savedPrinter", postdata.printerName); 

          setViewBarcode(false);
          setBarcoodeData({ title: "", barcodeId: "", barcodeUrl: "", });
        }
      } else {
        let errortext = Object.keys(error).filter(key => error[key] !== "").map(key => `<div>${t(error[key])}</div>`).join("");
        errortext && customAlert('error', errortext, 6000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validatepostdata = (data: any) => {
    let error = {
      "printerName": FormValidation.nameValidation(data?.printerName, 255, 'Printer name'),
      "licensePlateNumber": FormValidation.nameValidation(data?.labelData?.licensePlateNumber, 8, 'License plate number'),
      "clientId": FormValidation.nameValidation(data?.labelData?.clientId, 255, 'ClientId'),
      "shipmentDate": FormValidation.dateValidation(data?.labelData?.shipmentDate, /^\d{2}\/\d{2}\/\d{4}$/, 'Shipment date'),
    }
    return Object.values(error)?.some(item => item !== "") ? error : false;
  }

  const handleViewBarcode = (title: any, id: string, url: string) => {
    setViewBarcode(true);
    setBarcoodeData((prevValues) => ({
      ...prevValues,
      title: title,
      barcodeId: id,
      barcodeUrl: url,
    }));
  };

  useEffect(() => {
    const filteredHeaders = ShipmentReportsConstant.TABLE_HEADERS.filter(
      (header) => {
        return (
          selectedColumns.includes(header.handle) ||
          header.handle === "shipment_id"
        );
      }
    );
    const filteredValues = ShipmentReportsConstant.TABLE_VALUES.filter(
      (value) => {
        return selectedColumns.includes(value) || value === "shipment_id";
      }
    );
    setFilteredTableHeaders(filteredHeaders);
    setFilteredTableValues(filteredValues);
  }, [selectedColumns]);

  useEffect(() => {
    fetchConfigData();
  }, []);

  const handleSelectedFilter = (e: any, key: any) => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      [key]: e,
    }));
  };

  return (
    <div className="row">
      <div className="col-md-11 ms-auto px-4">


        <WindowHeightComponent>
          <div className="d-flex flex-column overflow-auto h-100">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
              {t("Shipment overview")}
            </div>
            <div className="flex-1 overflow-auto d-flex overflow-auto h-100 flex-column">
              <div className="d-flex gap-2 mb-3 justify-content-xl-between justify-content-end flex-lg-wrap flex-xl-nowrap flex-wrap justify-content-lg-end">
                <div className="d-flex gap-2 flex-wrap multi-select-reports-header">
                  <MultiSelect
                    placeholder={t("Brand Partner")}
                    name="brand_partner"
                    isMulti={true}
                    options={getSelectOptions(
                      configDetails.BrandPartner,
                      "brand_partner_id",
                      "title"
                    )}
                    standards={selectedFilterData?.brand_partner_id}
                    handleChange={(e) => handleSelectedFilter(e, "brand_partner_id")}
                  />
                  <MultiSelect
                    placeholder={t("Product group")}
                    name="product_group"
                    isMulti={true}
                    standards={selectedFilterData.product_group}
                    options={getSelectOptions(
                      selectedFilterData.brand_partner_id.length > 0 &&
                        configDetails?.FilteredProductGroup?.length > 0
                        ? configDetails.FilteredProductGroup
                        : configDetails.ProductGroup,
                      "product_group_id",
                      "title"
                    )}
                    handleChange={(e) => handleSelectedFilter(e, "product_group")}
                  />
                  <MultiSelect
                    placeholder={t("Location")}
                    name="location"
                    isMulti={true}
                    standards={selectedFilterData.location}
                    options={getSelectOptions(
                      selectedFilterData.brand_partner_id.length > 0 &&
                        configDetails?.FilteredWarehouseLocation?.length > 0
                        ? configDetails.FilteredWarehouseLocation
                        : configDetails.WarehouseLocation,
                      "warehouse_location_id",
                      "title"
                    )}
                    handleChange={(e) => handleSelectedFilter(e, "location")}
                  />
                  <MultiSelect
                    placeholder={t("Publish status")}
                    name="publish_status"
                    isMulti={true}
                    standards={selectedFilterData.publish_status}
                    options={publish_status_options}
                    handleChange={(e) => handleSelectedFilter(e, "publish_status")}
                  />

                  <div className="report-date-container">
                    <DatePicker
                      autoComplete="off"
                      name="dateof_delivery"
                      dateFormat={user?.date_format}
                      placeholderText={t("Date of Delivery")}
                      selected={selectedFilterData.dateof_delivery}
                      showMonthDropdown
                      showYearDropdown
                      className="w-100"
                      calendarStartDay={1}
                      dropdownMode="select"
                      yearDropdownItemNumber={4}
                      isClearable={true}
                      onChange={(e) => handleSelectedFilter(e, "dateof_delivery")}
                      onKeyDown={(e: any) => {
                        if (
                          e.target.value.length >= 10 &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {!selectedFilterData.dateof_delivery ? (
                      <span className="cal-open-button">
                        <Calendar_fill />
                      </span>
                    ) : null}
                  </div>

                  <div className="report-date-container">
                    <DatePicker
                      autoComplete="off"
                      name="from_date"
                      dateFormat={user?.date_format}
                      placeholderText={t("From date")}
                      selected={selectedFilterData.from_date}
                      className="w-100"
                      yearDropdownItemNumber={4}
                      showMonthDropdown
                      showYearDropdown
                      maxDate={selectedFilterData.to_date}
                      calendarStartDay={1}
                      dropdownMode="select"
                      isClearable={true}
                      onChange={(e) => handleSelectedFilter(e, "from_date")}
                      onKeyDown={(e: any) => {
                        if (
                          e.target.value.length >= 10 &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {!selectedFilterData.from_date ? (
                      <span className="cal-open-button">
                        <Calendar_fill />
                      </span>
                    ) : null}
                  </div>

                  <div className="report-date-container">
                    <DatePicker
                      autoComplete="off"
                      name="to_date"
                      dateFormat={user?.date_format}
                      placeholderText={t("To date")}
                      selected={selectedFilterData.to_date}
                      minDate={selectedFilterData.from_date}
                      showMonthDropdown
                      yearDropdownItemNumber={4}
                      showYearDropdown
                      calendarStartDay={1}
                      isClearable={true}
                      className="w-100"
                      dropdownMode="select"
                      onChange={(e) => handleSelectedFilter(e, "to_date")}
                      onKeyDown={(e: any) => {
                        if (
                          e.target.value.length >= 10 &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {!selectedFilterData.to_date ? (
                      <span className="cal-open-button">
                        <Calendar_fill />
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex gap-4">
                  <Button
                    title={t("Apply")}
                    className="submit-btn"
                    handleClick={() => fetchShipmentReports(null, true)}
                  />
                  <Button
                    title={t("Filter")}
                    className="submit-btn"
                    handleClick={() => setFilterPopup(true)}
                  />
                  <IconWithLabel
                    svgData={IconData.DownloadIcon}
                    handleClick={() => handleDownload()}
                    classname1="hoversvg"
                  />
                </div>
              </div>
              <div className="flex-1 overflow-auto">
                {shipmentDetails ? (
                  <TableStructure
                    isAction
                    data={shipmentDetails}
                    headers={filteredTableHeaders}
                    values={filteredTableValues}
                    handleDownload={(e) => handleDownload([e])}
                    handlePrint={handleViewBarcode}
                    actionClass=""
                    flatValues={["product_group"]}
                  />
                ) : (
                  Object.keys(shipmentDetails).length != 0 && (
                    <span className="text-center">{"No results"}</span>
                  )
                )}
              </div>

            </div>
            <div className="row m-0 mt-2 position-relative">
              {selectedFilterData.totalLength > selectedFilterData.limit && selectedFilterData.totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={selectedFilterData.totalPages}
                  handlePaginationClick={handlePaginationClick}
                />
              )}
              <div className="col-md-6 float-start p-0">
                <Button
                  className="back-btn shadow-none float-start text-decoration-none"
                  handleClick={() => navigate("/reports")}
                >
                  {t(CommonConstants.BACK)}
                </Button>
              </div>
            </div>
          </div>
        </WindowHeightComponent>
      </div>


      {filterPopup && (
        <Popup
          title={t("Select the columns to display in the shipment data")}
          body={
            <ShipmentReportsFilterBody
              setSelectedColumns={setSelectedColumns}
              selectedColumns={selectedColumns}
              filterValues={filterValues}
            />
          }
          bodyclassName="text-revert"
          notext={t("Close")}
          cancel={() => setFilterPopup(false)}
        />
      )}

      {viewBarcode && (
        <Popup
          body={
            <BarcodeBody
              handlePrint={handlePrint}
              savedPrinter={savedPrinter}
            />
          }
          title={t("Print barcode")}
          cancel={() => { setViewBarcode(false); setBarcoodeData({ title: "", barcodeId: "", barcodeUrl: "", }) }}
        />
      )}

    </div >
  );
};

export default ShipmentReportsOrganism;
