import React, { useEffect, useState } from 'react';
import styles from '../organisms/organisms.module.css';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import IconData from '../../static/IconData';
import { t } from '../../pages/Translations/TranslationUtils';

interface ViewFixedIssueMoleculeProps {
  item: any,
  configdata: any,
  idname: string
}

const ViewFixedIssueMolecule: React.FC<ViewFixedIssueMoleculeProps> = ({ item, configdata, idname }) => {
  return (
    item?.quality_control_issues?.issue_id != null && (
    <div className="col-12 h-100 " key={item[idname]}>
      <div className='p-2 h-100' style={{ backgroundColor: (CommonShipmentServices.getFilteredTitle(configdata['IssueType'], 'issue_type_id', item?.quality_control_issues?.issue_id, 'color')) }}>

        <div className={`d-flex justify-content-end ${styles.qcedit}`}>
          <div
            className={`me-2 mt-2 ${styles.qcedit}`}>
            {IconData.SuccessGreen}
          </div>
        </div>
        <div className="row align-items-center px-3 ">

          {(item?.quality_assurance_issue_info?.repair_as != null || item?.quality_assurance_issue_info?.work_area != null || item?.quality_assurance_issue_info?.issue_siz != null) ?
            <>
              <div className="col-3 text-center">
                <div className={`${styles.qclabel} mb-1`}> {IconData.Issue}</div>
              </div>
              <div className="col-9 pe-0">
                <div className={`${styles.qcvalue} mb-1 text-truncate `}>{CommonShipmentServices.getFilteredTitle(configdata['IssueSize'], 'issue_size_id', item?.quality_assurance_issue_info?.issue_siz)}</div>
              </div>
              <div className="col-3 text-center">
                <div className={`${styles.qclabel} mb-1`}>{IconData.Zone}</div>
              </div>
              <div className="col-9 pe-0">
                <div className={`${styles.qcvalue} mb-3 text-truncate`}>{item.zone_id == 0 ? 'All' : CommonShipmentServices.getFilteredTitle(configdata['RepairAssessment'], 'repair_assessment_id', Number(item?.quality_assurance_issue_info?.repair_as))}</div>
              </div>
              <div className="col-3 text-center">
                <div className={`${styles.qclabel} `}>{IconData.Issuetype}</div>
              </div>
              <div className="col-9 pe-0">
                <div className={`${styles.qcvalue} text-truncate`}>{CommonShipmentServices.getFilteredTitle(configdata['WorkArea'], 'work_area_id', item?.quality_assurance_issue_info?.work_area)}</div>
              </div>
              {/* Please do not remove this block of code, it may be required for future */}
              {/* <div className="col-3 text-center">
                <div className={`${styles.qclabel} `}>{IconData.Issuetype}</div>
              </div>
              <div className="col-9 pe-0">
                <div className={`${styles.qcvalue} text-truncate`}>{CommonShipmentServices.getFilteredTitle(configdata['IssueLocation'], 'issue_location_id', item.quality_assurance_issue_info.issue_loc)}</div>
              </div> */}
            </>
            : <div className="p-3 text-center">
              {t('Issues has been forwarded')}
            </div>
          }
        </div>
      </div>
    </div>
    )
  );
};

export default ViewFixedIssueMolecule;
